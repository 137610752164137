import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

// Custom Components
import UserContext from '@/context/UserContext/UserContext';
import NavDrawerLink from '@/ui/NavDrawer/NavDrawerLink';
import ThemeContext from '@/context/ThemeContext/ThemeContext';
import {getNavDrawerBgLevelColors} from '@/lib/colorUtilities';

// Types
import { MenuType } from '@/@types/common';
import { UserContextType } from '@/context/UserContext/UserContext';
import { Box, Collapse } from '@mui/material';
import TenantContext, { TenantContextType } from '@/context/TenantContext/TenantContext';

export interface INavDrawer {
  menu: MenuType[];
  open: boolean;
  toggleOpen: () => void;
  collapsedNavLinks: string[]
  onNavLinkCollapse: (name: string) => void;
}

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});
const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(56px + 1px)`,
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  minHeight: 48,
  borderTop: 0,
  borderLeft: 0,
  borderRight: 0,
  borderStyle: 'solid',
  borderColor: theme.palette.primary.main,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));



const NavDrawer: FunctionComponent<INavDrawer> = (
  props: React.PropsWithChildren<INavDrawer>
) => {
  const userContext = useContext(UserContext) as UserContextType;
  const tenantContext = useContext(TenantContext) as TenantContextType;
  const themeContext = useContext(ThemeContext);
  const { menu, open, toggleOpen, collapsedNavLinks, onNavLinkCollapse } = props;
  
  const [crestUrl, setCrestUrl] = useState<string>('');

  const { t } = useTranslation();

  const theme = useTheme();

  const levelColors = getNavDrawerBgLevelColors(20);

  const handleDrawerClose = () => {
    toggleOpen();
  };

  useEffect(() => {
    const hasGrb = tenantContext?.tenantTheme?.crest ? true : false;
    if(hasGrb === true) {
      setCrestUrl(`data:${tenantContext?.tenantTheme?.crest_type};base64, ${tenantContext?.tenantTheme?.crest}`);
    }
  }, [tenantContext]);

  const generateNavDrawerLink = (m: MenuType,level:number) =>{
    if (m.nested && m.nested.length>0) {
      const elements:Array<JSX.Element> = []

      m.nested.forEach(item => {
        if (item.needPermission && userContext.hasAnyPermission(item.needPermission)) {
          elements.push(generateNavDrawerLink(item, level+1))
        }
      })

      const isCollapsed = collapsedNavLinks.find((x) => m.name===x) !== undefined;

      const [collapsed,setCollapsed] = useState(isCollapsed);

      const handleCollapse = () => {
        setCollapsed(prevState => !prevState);
        onNavLinkCollapse(m.name);
      }
      
      return (
        <Box  key={'nav-drawer-link-container-'+m.name}
         bgcolor={collapsed?levelColors[level]:'initial'}
          sx={{
            transition: "background-color 0.3s"
          }}
        >
          <NavDrawerLink
            level={level}
            nested={m.nested}
            key={'nav-drawer-link-'+m.name}
            name={m.name}
            open={open}
            to={m.path}
            text={t(m.ttoken)}
            ttoken={m.ttoken}
            icon={m.icon}
            collapse={handleCollapse}
            collapsed={collapsed}
          />
          <Collapse aria-label={'nav-drawer-collapse-'+m.name} key={'nav-drawer-collapse-'+m.name} in={collapsed}>
            <List component="div" disablePadding>
              {elements}
            </List>
          </Collapse>
        </Box>
      )
    }
    else {
      return (
        <NavDrawerLink
          level={level}
          nested={m.nested}
          key={'nav-drawer-link-'+m.name}
          name={m.name}
          open={open}
          to={m.path}
          text={t(m.ttoken)}
          ttoken={m.ttoken}
          icon={m.icon}
        />
      )
    }
  }

  return (
    <aside id="nav-drawer">
      <Drawer variant="permanent" open={open} >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List disablePadding sx={{zIndex: 20, bgcolor: theme.palette.background.paper}}>
          {menu.map((m: MenuType,index:number) => {
            if (
              m.needPermission !== undefined &&
              !userContext.hasAnyPermission(m.needPermission)
            ) {
              return null;
            }
            return generateNavDrawerLink(m,0);
          })}
        </List>
        {crestUrl !== '' ? (
          <Box sx={{ position: "absolute", bottom: 10, width: "100%", zIndex: 10 }} >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img
                src={crestUrl}
                width={'85%'} // Don't set this at 80% or below unless you want to deal with weird bugs in Chrome
                style={{
                  transition: "width 0.3s",
                }}
              />
            </Box>
          </Box>
        ) : null}
      </Drawer>
    </aside>
  );
};

export default NavDrawer;
