import { IModel } from "@/@types/models/model";
import coreFields from "./helpers/coreFields";

const ToponimTipovi: IModel = {
  title: "Toponim Tipovi",
  apiPath: "core/toponim-tipovi",
  forms: {
    "default": {
      fields: ["id", "toponim_tip"]
    }
  },
  listViews: {
    "default": {
      fields: ["id", "toponim_tip"],
      hidden: ["id"]
    }
  },
  fields: [
    coreFields.id,
    {
      title: "Naziv",
      source: "toponim_tip",
      ttoken: "data.toponim_tip",
      type: "text",
      validation: {
        maxLength: 50
      }
    },
    {
      title: "Naziv Translation Token",
      source: "ttoken",
      ttoken: "data.ttoken",
      type: "text",
      validation: {
        maxLength: 50
      }
    },
    {
      title: "App Permission Id",
      source: "app_permission_id",
      ttoken: "data.app_permission_id",
      type: "numeric"
    }
  ]
}

export default ToponimTipovi;