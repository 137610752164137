import React from 'react'
import { useTranslation } from 'react-i18next';

import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import LockOpenIcon from '@mui/icons-material/LockOpenOutlined';
import LockIcon from '@mui/icons-material/LockOutlined';

type DetachListItemProps = {
    handleDetach: (newState: boolean) => void
    closeMenu: () => void
    isDetached: boolean
}

const DetachListItem = (props: DetachListItemProps) => {

    const { t } = useTranslation();

    const { handleDetach, closeMenu, isDetached } = props;

    const handleClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        closeMenu();
        handleDetach(!isDetached);
    }

    return (
        <MenuItem
            onClick={handleClick}
        >
            <ListItemIcon>
                {!isDetached ?
                    <LockOpenIcon color='primary' fontSize='small'/>:
                    <LockIcon color='primary' fontSize='small'/>
                }
            </ListItemIcon>
            <ListItemText>
            {!isDetached ?
                t("buttons.detach") :
                t("buttons.attach")
            }
            </ListItemText>
        </MenuItem>
    )
}

export default DetachListItem;