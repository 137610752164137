import { IModel } from '@/@types/models/model';
import coreFields from './helpers/coreFields';
import commonFields from './helpers/commonFields';

const NerazvrstaneCeste: IModel = {
  title: 'Nerazvrstane ceste',
  apiPath: 'nerazvrstane-ceste',
  forms: {
    default: {
      fields: [
        'id',
        'oznaka',
        'kategorija_ceste_id',
        'tip_kolnik_id',
        'vrsta_ceste_id',
        'opis',
        'smjer_ceste_id',
        'dionica_od',
        'dionica_do',
        'naselje',
        'duljina',
        'kc',
        'vlasnistvo',
        'ko',
        'stac_od',
        'stac_do',
      ],
    },
  },
  listViews: {
    default: {
      fields: [
        'id',
        'oznaka',
        'kategorija_ceste_id',
        'tip_kolnik_id',
        'vrsta_ceste_id',
        'opis',
        'smjer_ceste_id',
        'dionica_od',
        'dionica_do',
        'naselje',
        'duljina',
        'kc',
        'vlasnistvo',
        'ko',
        'stac_od',
        'stac_do',
        "docs"
      ],
    },
    sidebar: {
      fields: ['id', 'oznaka', 'kc', 'show_on_map'],
    },
  },
  fields: [
    coreFields.id,
    {
      title: 'oznaka',
      source: 'oznaka',
      ttoken: 'nerazvrstane_ceste:nerazvrstane_ceste.oznaka',
      type: 'text',
      readonly: true,
      filter: true,
    },
    {
      title: 'kategorija_ceste_id',
      source: 'kategorija_ceste_id',
      ttoken: 'nerazvrstane_ceste:nerazvrstane_ceste.kategorija',
      type: 'picker',
      items: {
        labels: ['1', '2', '3', '4'],
        values: [1, 2, 3, 4],
      },
      readonly: true,
      filter: true,
    },
    {
      title: 'tip_kolnik_id',
      source: 'tip_kolnik_id',
      ttoken: 'nerazvrstane_ceste:nerazvrstane_ceste.tip_kolnik',
      type: 'picker',
      items: {
        labels: [
          'nerazvrstane_ceste:tipovi_kolnika.asfalt',
          'nerazvrstane_ceste:tipovi_kolnika.makadam',
        ],
        values: [1, 2],
      },
      translate: true,
      readonly: true,
      filter: true,
    },
    {
      title: 'vrsta_ceste_id',
      source: 'vrsta_ceste_id',
      ttoken: 'nerazvrstane_ceste:nerazvrstane_ceste.vrsta',
      type: 'picker',
      items: {
        labels: [
          'nerazvrstane_ceste:vrste_ceste.cesta',
          'nerazvrstane_ceste:vrste_ceste.prilaz',
          'nerazvrstane_ceste:vrste_ceste.poljski_put',
        ],
        values: [1, 2, 3],
      },
      translate: true,
      readonly: true,
      filter: true,
    },
    {
      title: 'opis',
      source: 'opis',
      ttoken: 'nerazvrstane_ceste:nerazvrstane_ceste.opis',
      type: 'text',
      readonly: true,
      filter: true,
    },
    {
      title: 'smjer_ceste_id',
      source: 'smjer_ceste_id',
      ttoken: 'nerazvrstane_ceste:nerazvrstane_ceste.smjer',
      type: 'picker',
      items: {
        labels: ['nerazvrstane_ceste:smjer_ceste.dvosmjerno'],
        values: [1],
      },
      translate: true,
      readonly: true,
      filter: true,
    },
    {
      title: 'dionica_od',
      source: 'dionica_od',
      ttoken: 'nerazvrstane_ceste:nerazvrstane_ceste.dionica_od',
      type: 'text',
      readonly: true,
      filter: true,
    },
    {
      title: 'dionica_do',
      source: 'dionica_do',
      ttoken: 'nerazvrstane_ceste:nerazvrstane_ceste.dionica_do',
      type: 'text',
      readonly: true,
      filter: true,
    },
    {
      title: 'naselje',
      source: 'naselje',
      ttoken: 'nerazvrstane_ceste:nerazvrstane_ceste.naselje',
      type: 'text',
      readonly: true,
      filter: true,
    },
    {
      title: 'duljina',
      source: 'duljina',
      ttoken: 'nerazvrstane_ceste:nerazvrstane_ceste.duljina',
      type: 'numeric',
      readonly: true,
      filter: true,
    },
    {
      title: 'kc',
      source: 'kc',
      ttoken: 'nerazvrstane_ceste:nerazvrstane_ceste.kc',
      type: 'text',
      readonly: true,
      filter: true,
    },
    {
      title: 'vlasnistvo',
      source: 'vlasnistvo',
      ttoken: 'nerazvrstane_ceste:nerazvrstane_ceste.vlasnistvo',
      type: 'text',
      readonly: true,
      filter: true,
    },
    {
      title: 'ko',
      source: 'ko',
      ttoken: 'nerazvrstane_ceste:nerazvrstane_ceste.ko',
      type: 'text',
      readonly: true,
      filter: true,
    },
    {
      title: 'stac_od',
      source: 'stac_od',
      ttoken: 'nerazvrstane_ceste:nerazvrstane_ceste.stac_od',
      type: 'numeric',
      readonly: true,
      filter: true,
    },
    {
      title: 'stac_do',
      source: 'stac_do',
      ttoken: 'nerazvrstane_ceste:nerazvrstane_ceste.stac_do',
      type: 'numeric',
      readonly: true,
      filter: true,
    },
    {
      title: 'geometry',
      source: 'geom',
      ttoken: 'comments.geometry',
      type: 'wkt',
      sourceProjection: 'EPSG:3765',
      targetProjection: 'EPSG:3857',
      readonly: true,
    },
    {
      title: '',
      source: 'show_on_map',
      ttoken: '',
      tooltip: 'buttons.show_on_map',
      type: 'button',
      icon: 'fmd_good',
      action: 'show_on_map',
      padding: 'none',
      sort: false,
      // search: false
    },
    coreFields.gid,
    coreFields.docs,
    coreFields.created_by,
    coreFields.created_on,
    coreFields.modified_by,
    coreFields.modified_on,
  ],
};

export default NerazvrstaneCeste;
