import * as React from 'react';
import { FunctionComponent, useContext, useEffect, useRef, useMemo } from 'react';

// OpenLayers
import OlOverlay from 'ol/Overlay';

// Custom components
import MapContext from '@/context/MapContext/MapContext';
import { getDefinedOptions, getEvents } from '@/lib/olHelpers';

// Types
import { MapContextType } from '@/context/MapContext/MapContext';
import { IPopupOverlay } from '@/@types/components/Map/Overlays';

const PopupOverlay: FunctionComponent<IPopupOverlay> = (props) => {
  const context = useContext(MapContext) as MapContextType;
  const overlayDiv = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<OlOverlay | undefined>(undefined); // Keep track of the overlay instance

  // Memoize default options to prevent re-creation on each render
  const defaultOptions = useMemo(
    () => ({
      autoPan: true,
      autoPanAnimation: { duration: 100 },
      element: overlayDiv.current,
      ...props, // Spread props to include passed options like position, etc.
    }),
    [props] // Only update when props change
  );

  // Memoize events to avoid unnecessary effect executions
  const events = useMemo(
    () => ({
      change: undefined,
      'change:element': undefined,
      'change:map': undefined,
      'change:offset': undefined,
      'change:position': undefined,
      'change:positioning': undefined,
      error: undefined,
      propertychange: undefined,
    }),
    []
  );

  useEffect(() => {
    overlayRef.current?.setPosition(props.position)
    if (props.position) {
      setTimeout(()=>{overlayRef.current?.panIntoView()},0);
    }
  },[
    props.position
  ])

  useEffect(() => {
    // Define options with memoized default options and refs
    const definedOptions = getDefinedOptions({ ...defaultOptions, element: overlayDiv.current });

    // Initialize the overlay only if it doesn't exist
    if (!overlayRef.current) {
      overlayRef.current = new OlOverlay(definedOptions);
    }

    const overlay = overlayRef.current;

    if (context.map) {
      const mapOverlay = context.map.getOverlayById(props.id);
      if (mapOverlay) {
        // Remove old overlay with the same id
        context.map.removeOverlay(mapOverlay);
      }
      // Add the new overlay to the map
      context.map.addOverlay(overlay);

      // Set position if provided
      if (props.position) {  
        overlay.setPosition(props.position);
      }
    } else {
      // If map not initialized, push overlay to init options for later use
      context.initOptions.overlays.push(overlay);
    }

    // Add event listeners
    const olEvents = getEvents(events, props);
    for (let eventName in olEvents) {
      //@ts-ignore
      overlay.on(eventName, olEvents[eventName]);
    }

    // Cleanup on component unmount
    return () => {
      if (context.map && overlay) {
        context.map.removeOverlay(overlay);
      }
    };
  }, [context.map, props.id, props.position]); // Limit dependencies to important ones

  return (
    <div
      id={`overlay-${props.id}`}
      ref={overlayDiv}
      className="ol-react-popup"
      style={{ display: 'block' }}
    >
      <div id="react-popup-content">
        {Array.isArray(props.children) ? props.children.map((child) => child) : props.children}
      </div>
    </div>
  );
};

//@ts-ignore TODO: isTouchDevice does not exist on PopupOverlay
PopupOverlay.isTouchDevice = function () {
  try {
    document.createEvent('TouchEvent');
    return true;
  } catch (e) {
    return false;
  }
};

export default PopupOverlay;
