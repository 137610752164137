import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import Toolbar from '@mui/material/Toolbar';
import EditIcon from '@mui/icons-material/Edit';
import AddCommentIcon from '@mui/icons-material/AddComment';

// Custom Components
import DialogActionButtonCancel from '../Dialog/ActionButtons/DialogActionButtonCancel';
import DialogActionButtonAdd from '../Dialog/ActionButtons/DialogActionButtonAdd';
import DialogActionButtonSave from '../Dialog/ActionButtons/DialogActionButtonSave';
import DraggableDialog from '@/ui/Dialog/DraggableDialog';
import DialogHeader from '@/ui/Dialog/DialogHeader';
import DialogToolbarHeading from '@/ui/Dialog/DialogToolbarHeading';
import DialogToolbarButtonClose from '@/ui/Dialog/ToolbarButtons/DialogToolbarButtonClose';
import ToolbarFillContent from '@/ui/Toolbar/ToolbarFillContent';
import DialogBody from '@/ui/Dialog/DialogBody';
import useFormController from '@/components/useFormController';
import GridContainer from '@/ui/Grid/GridContainer';
import FormContent from '@/components/FormContent';

import DialogContext, {
  IDialogProps,
} from '@/context/DialogContext/DialogContext';
import SnackbarContext from '@/context/SnackbarContext/SnackbarContext';
import DataController from '@/lib/DataController';
import GFIModelFields from '@/models/gfi_model_fields';
import DialogToolbarMenuItemDelete from '../Dialog/ToolbarMenuItems/DialogToolbarMenuItemDelete';
import DialogToolbarMenuButton from '../Dialog/DialogToolbarMenuButton';

const ModelAdminFieldsDialog = (props: IDialogProps) => {
  const { mode, dataId, onClose, form, initialRecord, modelId } = props;

  const { t } = useTranslation();
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);

  const dc = new DataController(
    GFIModelFields,
    'system/models/' + String(modelId) + '/fields'
  );

  const close = (result: any) => {
    if (onClose) {
      onClose(result);
    }

    dialogContext.hideDialog();
  };

  const {
    record,
    validation,
    fields,
    onFieldChange,
    doInsert,
    doUpdate,
    doDelete,
    dataChanged,
  } = useFormController({
    mode,
    recordId: dataId,
    form,
    dc,
    onClose: close,
    initialRecord: initialRecord,
  });

  const disableSaveButton = !dataChanged;

  const handleFieldChange = (value: any, source: string) => {
    if (onFieldChange) {
      onFieldChange(value, source);
    }
  };

  const handleClose = () => {
    close({ success: false });
  };

  const handleInsert = () => {
    if (doInsert) {
      doInsert()
        .then((result) => {
          if (result.success) {
            const msg = 'messages.insert_model_field_success';
            snackbarContext.showNotification(msg, 'success');
            close({ dataChanged: true, action: 'insert' });
          } else if (result.error) {
            const msg = 'error.insert_model_field';
            snackbarContext.showNotification(msg, 'error');
          } else if (result.validationPass === false) {
            // do nothing
          }
        })
        .catch((resp) => {
          snackbarContext.showNotification(resp.error, 'error');
        });
    }
  };

  const handleUpdate = () => {
    if (doUpdate) {
      doUpdate()
        .then((result) => {
          if (result.success) {
            const msg = 'messages.update_model_field_success';
            snackbarContext.showNotification(msg, 'success');
            close({ dataChanged: true, action: 'update' });
          } else if (result.error) {
            const msg = 'error.update_model_field';
            snackbarContext.showNotification(msg, 'error');
          } else if (result.validationPass === false) {
            // do nothing
          }
        })
        .catch((resp) => {
          snackbarContext.showNotification(resp.error, 'error');
        });
    }
  };

  const handleDelete = () => {
    doDelete()
      .then((result) => {
        if (result.success) {
          const msg = 'messages.delete_model_field_success';
          snackbarContext.showNotification(msg, 'success');
          close({ dataChanged: true, action: 'delete' });
        } else if (result.error) {
          const msg = 'error.delete_model_field';
          snackbarContext.showNotification(msg, 'error');
        }
      })
      .catch((resp) => {
        if (!resp.success && !resp.canceled) {
          snackbarContext.showNotification(resp.error, 'error');
        }
      });
  };

  return (
    <DraggableDialog open maxWidth="sm" onClose={handleClose}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters>
          {mode === 'update' ? (
            <EditIcon sx={{ transform: 'scale(1.3)' }} />
          ) : mode === 'insert' ? (
            <AddCommentIcon sx={{ transform: 'scale(1.3)' }} />
          ) : null}
          <DialogToolbarHeading>
            {mode === 'insert'
              ? t('admin:titles.add_field')
              : mode === 'update'
                ? t('admin:titles.edit_field')
                : null}
          </DialogToolbarHeading>
          <ToolbarFillContent />
          {mode === 'update'
            ?
            <DialogToolbarMenuButton>
              <DialogToolbarMenuItemDelete onClick={handleDelete} />
            </DialogToolbarMenuButton>
            : null
          }
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <Box m={2}>
          <GridContainer direction="row" spacing={3}>
            <FormContent
              fieldNames={dc.getFormFieldsNames(form)}
              // @ts-ignore
              record={record}
              validation={validation}
              fields={fields}
              onFieldChange={handleFieldChange}
              mode={mode}
              columns={1}
            />
          </GridContainer>
        </Box>
      </DialogBody>
      <DialogActions>
        <DialogActionButtonCancel variant="outlined" onClick={handleClose} />
        {mode === 'insert' ? (
          <DialogActionButtonAdd variant="contained" onClick={handleInsert} />
        ) : mode === 'update' ? (
          <DialogActionButtonSave variant="contained" onClick={handleUpdate} disabled={disableSaveButton} />
        ) : null}
      </DialogActions>
    </DraggableDialog>
  );
};

export default ModelAdminFieldsDialog;
