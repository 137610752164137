import React, { SyntheticEvent, useContext } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Box, Typography, Stack } from '@mui/material';

// MUI Icons
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

// Custom Components
import { GridContainer } from '@/ui/Grid';
import MapCardActionButton from '@/ui/MapCard/MapCardActionButton';
import FormContent from '@/components/FormContent';
import useFormController from '@/components/useFormController';
import { FormControllerProps } from '@/@types/components/formController';

import SnackbarContext from '@/context/SnackbarContext/SnackbarContext';

const KucaZaOdmorForm = (props: FormControllerProps) => {
  const { showNotification } = useContext(SnackbarContext);

  const { t } = useTranslation();

  const { validation, fields, onFieldChange, record } =
    useFormController(props);

  const { onClose, dc } = props;

  const handleClickYes = (evt: SyntheticEvent) => {
    updateRecordWithStatus(1);
  };

  const handleClickNo = (evt: SyntheticEvent) => {
    updateRecordWithStatus(-1);
  };

  const handleClickUnknown = (evt: SyntheticEvent) => {
    updateRecordWithStatus(0);
  };

  const updateRecordWithStatus = (status: number) => {
    dc.UpdateRecord(record.id as number, {
      komentar: record.komentar,
      status,
    }).then((result) => {
      console.log('Result:', result);
      // @ts-ignore
      if (result.success) {
        showNotification('update', 'success');
        onClose({ dataChanged: true, action: 'update' });
      } else {
        const msg = `Greška prilikom ažuriranja podataka!`;
        showNotification(msg, 'error');
      }
    });
  };

  return (
    <Box margin={2}>
      <GridContainer spacing={0}>
        <FormContent
          fields={fields}
          fieldNames={['komentar']}
          onFieldChange={onFieldChange}
          columns={1}
          mode="update"
          record={record}
          validation={validation}
        />
      </GridContainer>
      <Stack direction="column">
        <Typography variant="body2" paragraph>
          {t('ena:kuce_za_odmor.question')}
        </Typography>
        <Stack direction="row">
          <MapCardActionButton
            startIcon={<CheckIcon />}
            onClick={handleClickYes}
            variant="contained"
          >
            {t('common.yes')}
          </MapCardActionButton>
          <MapCardActionButton
            startIcon={<CloseIcon />}
            onClick={handleClickNo}
            variant="contained"
          >
            {t('common.no')}
          </MapCardActionButton>
          <MapCardActionButton
            startIcon={<QuestionMarkIcon />}
            onClick={handleClickUnknown}
            variant="outlined"
          >
            {t('common.unknown')}
          </MapCardActionButton>
        </Stack>
      </Stack>
    </Box>
  );
};

export default KucaZaOdmorForm;
