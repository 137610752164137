import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

//Material UI
import Toolbar from '@mui/material/Toolbar';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

//Material-UI Icons
import ContactsIcon from '@mui/icons-material/Contacts';
import PeopleIcon from '@mui/icons-material/People';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import MapIcon from '@mui/icons-material/Map';
import MailIcon from '@mui/icons-material/Mail';

//Custom Icons
import TenantIcon from '@/icons/TenantIcon';

//Custom components
import FormContent from '@/components/FormContent';
import { GridContainer, GridItem } from '@/ui/Grid';
import {
  CardActionButtonSave,
  CardHeading,
  CardToolbarButtonBack,
  CardToolbarMenuButton,
  CardToolbarMenuItemActivate,
  CardToolbarMenuItemDeactivate,
  CardToolbarMenuItemDelete,
} from '@/ui/Card';
import {
  FormAvatar,
  FormHeading,
  FormHeadingID,
  FormFillContent,
  FormTab,
  FormTabPanel,
  FormTabs,
} from '@/ui/Form';
import SnackbarContext from '@/context/SnackbarContext/SnackbarContext';
import DialogContext from '@/context/DialogContext/DialogContext';
import dataController from '@/lib/DataController';
// import imageModel from "Models/poslovniSubjektiImages";
// import ImageThumbnail from "UI/Image/ImageThumbnail";
// import ImageUploadDialog from "UI/Dialog/ImageUploadDialog";
// import SwapButton from "UI/Buttons/SwapButton";
import useFormController from '@/components/useFormController';
import { FormContentMode } from '@/components/FormContent';
import { SnackbarContextType } from '@/context/SnackbarContext/SnackbarContext';
import { IApiResponse } from '@/@types/lib/api';
import { DialogContextType } from '@/context/DialogContext/DialogContext';
import UserModel from '@/models/user';
import LicenceModel from '@/models/licence';
import TenantMapConfigTab from '@/views/Tenants/TenantConfig/TenantMapConfigTab';
import UsersTable from '../Users/UsersTable';
import { Box } from '@mui/material';
import ModelTable from '@/ui/Table/ModelTable';
import { DCRecord } from '@/@types/lib/dataController';
import TenantAdminLicencesDialog from '@/ui/AdminDialogs/TenantAdminLicencesDialog';
import TenantAdminUsersDialog from '@/ui/AdminDialogs/TenantAdminUsersDialog';

interface ITenantFormProps {
  dc: dataController;
  recordId: number;
  mode: FormContentMode;
  form: string;
}

function TenantForm(props: ITenantFormProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const snackbarContext = useContext(SnackbarContext) as SnackbarContextType;
  const dialogContext = useContext(DialogContext) as DialogContextType;

  const [currentTab, setCurrentTab] = useState(0);

  const { dc, recordId, mode, form } = props;
  const {
    record,
    validation,
    fields,
    onFieldChange,
    doUpdate,
    doDelete,
    doRefresh,
    doClose,
    pickerDataControllers,
    dataChanged,
  } = useFormController({
    mode,
    recordId: recordId,
    form,
    dc,
    onClose: close,
  });

  const dcLicences = new dataController(
    LicenceModel,
    'system/tenants/' + String(recordId) + '/licences'
  );
  const dcUsers = new dataController(
    UserModel,
    'system/tenants/' + String(recordId) + '/users'
  );

  const [userRecords, setUserRecords] = useState<Array<DCRecord>>();
  const [licenceRecords, setLicenceRecords] = useState<Array<DCRecord>>();

  // const { doUpdate, doDelete, doRefresh, doClose, validation, fields, pickerDataControllers, dataChanged, record } = props;
  // const { onFieldChange } = props;
  // const { redirect } = props;

  // const image_path = `business-subjects/${recordId}/images`;
  // const image_dc = new dataController(imageModel, image_path);

  // const memo_path = `business-subjects/${recordId}/memo-headers`;
  // const memo_dc = new dataController(imageModel, memo_path);

  // const mode = record && record.active ? "update" : "view";

  const handleClickBack = () => {
    if (doClose) {
      doClose()
        .then((resp) => {
          if (resp.success) {
            navigate('/admin/tenants');
          } else if (resp.shouldSave) {
            if (doUpdate) {
              doUpdate()
                .then((result) => {
                  if (result.success) {
                    const msg = 'messages.update_tenant_success';
                    snackbarContext.showNotification(msg, 'success');
                    navigate('/admin/tenants');
                  }
                })
                .catch((result) => {
                  if (
                    result.success === false &&
                    result.validationPass === false
                  ) {
                    //do nothing
                  } else {
                    const msg = 'error.update_tenant';
                    snackbarContext.showNotification(msg, 'error');
                  }
                });
            }
          }
        })
        .catch((result) => {
          snackbarContext.showNotification(result.error, 'error');
        });
    }
  };

  const handleTabChange = (value: number) => {
    setCurrentTab(value);
  };

  const handleUpdate = (evt: any) => {
    if (doUpdate) {
      doUpdate()
        .then((result) => {
          if (result.success) {
            const msg = 'messages.update_tenant_success';
            snackbarContext.showNotification(msg, 'success');
          }
        })
        .catch((result) => {
          if (result.success === false && result.validationPass === false) {
            //do nothing
          } else {
            const msg = 'error.update_tenant';
            snackbarContext.showNotification(msg, 'error');
          }
        })
        .finally(() => {
          doRefresh();
        });
    }
  };

  const openAddDialogUser = () => {
    dialogContext.showDialog(TenantAdminUsersDialog, {
      dc: dcUsers,
      mode: 'insert',
      // form: 'insert_on_tenant',
      initialRecord: { tenant_id: recordId },
      refreshRecords: getUsers,
      form: 'tenantuser_insert',
      tenantId: recordId,
      onClose: getUsers,
    });
  };

  const openAddDialogLicence = () => {
    dialogContext.showDialog(TenantAdminLicencesDialog, {
      mode: 'insert',
      form: 'insert_tenant_licence',
      dataId: undefined,
      type: "system",
      refreshRecords: getLicences,
      initialRecord: undefined,
      tenantId: recordId,
      onClose: getLicences,
    });
  };

  const handleDelete = (evt: any) => {
    if (doDelete) {
      doDelete()
        .then((result) => {
          if (result.success) {
            const msg = 'messages.delete_tenant_success';
            snackbarContext.showNotification(msg, 'success');
            navigate('/admin/tenants');
          }
        })
        .catch((result) => {
          const msg = 'error.delete_tenant';
          snackbarContext.showNotification(msg, 'error');
        });
    }
  };

  const handleRowActionUser = (
    action: string,
    id: number,
    record?: { [key: string]: any }
  ) => {
    if (action === 'edit') {
      navigate('/admin/users/' + id);
    }
  };

  const handleRowActionLicence = (
    action: string,
    id: number,
    record?: { [key: string]: any }
  ) => {
    if (action === 'edit') {
      dialogContext.showDialog(TenantAdminLicencesDialog, {
        mode: 'update',
        form: 'update_tenant_licence',
        dataId: id,
        type: "system",
        refreshRecords: getLicences,
        initialRecord: record ? record : undefined,
        tenantId: recordId,
      });
    }
  };

  /** ???? */
  // const handleActivate = (evt: any) => {
  //   businessSubjectService
  //     .activateBusinessSubject(recordId)
  //     .then((res: IApiResponse) => {
  //       if (res.success) {
  //         snackbarContext.showNotification("notifications.activate", "success");
  //         if (doRefresh) {
  //           doRefresh();
  //         }
  //       }
  //     })
  //     .catch((err: IApiResponse) => {
  //       console.error(err);
  //     });
  // };

  // const handleDeactivate = (evt: any) => {
  //   businessSubjectService
  //     .deactivateBusinessSubject(recordId)
  //     .then((res: IApiResponse) => {
  //       if (res.success) {
  //         snackbarContext.showNotification("notifications.deactivate", "success");
  //         navigate("/admin/tenants");
  //       }
  //     })
  //     .catch((err: IApiResponse) => {
  //       console.error(err);
  //     });
  // };
  /** ???? */

  // const handleUploadButton = () => {
  //   dialogContext.showDialog(ImageUploadDialog, {
  //     dc: image_dc,
  //     baseRecordId: recordId,
  //     service: businessSubjectService,
  //     onClose: handleCloseDialog
  //   });
  // };

  // const handleUploadMemoButton = () => {
  //   dialogContext.showDialog(ImageUploadDialog, {
  //     dc: memo_dc,
  //     baseRecordId: recordId,
  //     service: businessSubjectMemoService,
  //     onClose: handleCloseDialog
  //   });
  // }

  // const viewLogo = (uuid: string) => {
  //   return businessSubjectService.getFullImage(recordId, uuid);
  // };

  // const viewMemo = (uuid: string) => {
  //   return businessSubjectService.getFullImage(recordId, uuid);
  // };

  // const deleteLogo = (uuid: string) => {
  //   return businessSubjectService.deleteImage(recordId, uuid);
  // };

  // const deleteMemo = (uuid: string) => {
  //   return businessSubjectMemoService.deleteImage(recordId, uuid);
  // };

  /** RESULT TYPE ???? */
  const handleCloseDialog = (result: any) => {
    if (result.dataChanged) {
      if (doRefresh) {
        doRefresh();
      }
    }
    dialogContext.hideDialog();
  };

  const commonProps = {
    record: record,
    validation: validation,
    onFieldChange: onFieldChange,
    fields: fields,
    mode: mode,
    pickerDataControllers: pickerDataControllers,
  };

  //console.log(commonProps)

  const title = record.host ? (record.host as string) : undefined;

  const showSaveButton =
    currentTab === 0 ||
    currentTab === 3 ||
    currentTab === 4 ||
    currentTab === 5;
  const disableSaveButton = !dataChanged;
  // const hasLogo =
  //   record.thumb && record.uuid && record.image_mime_type && record.file_name;
  // const hasMemo =
  //   record.memo_image !== null && record.memo_image !== undefined
  //     ? true
  //     : false;

  useEffect(() => {
    getLicences();
    getUsers();
  }, []);

  const getLicences = () => {
    dcLicences
      .GetData()
      .then((resp) => {
        if (resp.data && Array.isArray(resp.data) && resp.data.length > 0) {
          setLicenceRecords(resp.data);
        }
        else setLicenceRecords([])
      })
      .catch((resp) => {
        console.log(resp);
      })
      .finally(() => {});
  };

  const getUsers = () => {
    dcUsers
      .GetData()
      .then((resp) => {
        if (resp.data && Array.isArray(resp.data) && resp.data.length > 0) {
          setUserRecords(resp.data);
        }
      })
      .catch((resp) => {
        console.log(resp);
      })
      .finally(() => {});
  };

  return (
    <Card>
      <CardHeading>
        <Toolbar variant="dense" disableGutters={true}>
          <FormAvatar ariaLabel="tenant" icon={<TenantIcon />} />
          <FormHeading
            header={title}
            subheaderContent={<FormHeadingID id={recordId} />}
            dataChanged={dataChanged}
          />
          <FormFillContent />
          <CardToolbarButtonBack onClick={handleClickBack} />
        </Toolbar>
      </CardHeading>
      <CardContent style={{ paddingTop: 0 }}>
        <FormTabs
          value={currentTab}
          onChange={handleTabChange}
          identifier={dc.getSource()}
        >
          <FormTab
            id="basic"
            label={t('admin:common.tabs.basic')}
            icon={<BusinessCenterIcon />}
            aria-label="basic"
            value={0}
          />
          <FormTab
            id="licences"
            label={t('admin:tenant.tabs.licences')}
            icon={<ContactsIcon />}
            aria-label="licences"
            value={1}
          />
          <FormTab
            id="users"
            label={t('admin:tenant.tabs.users')}
            icon={<PeopleIcon />}
            aria-label="users"
            value={2}
          />
          <FormTab
            id="visuals"
            label={t('admin:tenant.tabs.visuals')}
            icon={<ColorLensIcon />}
            aria-label="visuals"
            value={3}
          />
          <FormTab
            id="map"
            label={t('admin:common.tabs.config')}
            icon={<MapIcon />}
            aria-label="map"
            value={4}
          />
          <FormTab
            id="mail"
            label={t('admin:common.tabs.mail')}
            icon={<MailIcon />}
            aria-label="mail"
            value={5}
          />
        </FormTabs>
        <FormTabPanel value={currentTab} index={0}>
          <GridContainer direction="row" spacing={3}>
            <GridItem>
              <GridContainer direction="row">
                <FormContent
                  title={t('admin:common.sections.basic') as string}
                  fieldNames={[
                    'id',
                    'tag',
                    'name_ttoken',
                    'tenant_state_id',
                    'host',
                    'created_on',
                    'created_by',
                    'modified_on',
                    'modified_by',
                  ]}
                  {...commonProps}
                  columns={2}
                />
              </GridContainer>
            </GridItem>
          </GridContainer>
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={1}>
          <ModelTable
            title={t('admin:tenant.sections.licences') as string}
            key={`tblid-tenant-admin-licences`}
            dc={dcLicences}
            records={licenceRecords}
            allowSelection="one"
            allowRowAction={() => true}
            onRowAction={handleRowActionLicence}
            viewName="tenant_admin"
            allowColumnPicker
            allowFilter={true}
            allowAdd={true}
            handleAdd={openAddDialogLicence}
            addLabel={t('buttons.new_f') as string}
          />
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={2}>
          <ModelTable
            title={t('admin:tenant.sections.users') as string}
            key={`tblid-tenant-admin-users`}
            dc={dcUsers}
            records={userRecords}
            allowSelection="one"
            allowRowAction={() => true}
            onRowAction={handleRowActionUser}
            viewName="tenant_admin"
            allowColumnPicker
            allowFilter={true}
            allowAdd={true}
            handleAdd={openAddDialogUser}
            addLabel={t('buttons.new_m') as string}
          />
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={3}>
          <GridContainer direction="row" spacing={3}>
            <GridItem>
              <GridContainer direction="row">
                <FormContent
                  title={t('admin:tenant.sections.visuals') as string}
                  fieldNames={[
                    'primary_main',
                    'primary_light',
                    'primary_dark',
                    'secondary_main',
                    'secondary_light',
                    'secondary_dark',
                  ]}
                  {...commonProps}
                  columns={3}
                />
                <FormContent
                  fieldNames={['background_image', 'crest']}
                  {...commonProps}
                  columns={2}
                />
              </GridContainer>
            </GridItem>
          </GridContainer>
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={4}>
          <GridContainer direction="row" spacing={3}>
            <GridItem xs={12}>
              <TenantMapConfigTab {...commonProps} />
            </GridItem>
          </GridContainer>
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={5}>
          <GridContainer direction="row" spacing={3}>
            <FormContent
              title={t('admin:tenant.sections.mail') as string}
              fieldNames={[
                'mail_smtp_server',
                'mail_port',
                'mail_from_address',
                'mail_from_name',
                'mail_user',
                'mail_pwd',
                'mail_password_reset_link',
                'mail_password_reset_subject',
                'mail_password_reset_body_plain',
                'mail_password_reset_body_html',
              ]}
              {...commonProps}
              columns={2}
            />
          </GridContainer>
        </FormTabPanel>
      </CardContent>
      <CardActions>
        <FormFillContent />
        {showSaveButton ? (
          <CardActionButtonSave
            onClick={handleUpdate}
            variant="contained"
            disabled={disableSaveButton}
          />
        ) : null}
      </CardActions>
    </Card>
  );
}

export default TenantForm;
