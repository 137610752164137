import React, { useEffect, useRef, useState } from 'react';

// MUI
import Box from '@mui/material/Box';

// Custom Components
import useWindowSize from '@/components/useWindowSize';

// Ui
import DataCentricPrimaryTable from '@/ui/DataCentric/DataCentricPrimaryTable';
import DataCentricSecondaryTable from '@/ui/DataCentric/DataCentricSecondaryTable';
import DataCentricMiniMap from '@/ui/DataCentric/DataCentricMiniMap';
import DataCentricImagesOverlay from '@/ui/DataCentric/DataCentricImagesOverlay';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';

// Lib
import dataController from '@/lib/DataController';

// Context
import { IDialogProps } from '@/context/DialogContext/DialogContext';

// Types
import { DCRecord } from '@/@types/lib/dataController';
import { IModel } from '@/@types/models/model';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { RowActionFn } from '@/@types/ui/Table';
import StyledPanelResizeHandle from '../PanelResize/StyledPanelResizeHandle';

export type DialogFormGenerateFunction = (record: DCRecord) => string;

export type DataCentricPageParams = {
  primaryModel: IModel;
  primaryTitleToken: string;
  primaryView: string | undefined;
  primaryAllowAdd?: boolean;
  primaryAllowView?: boolean;
  primaryAllowEdit?: boolean;
  primaryDialogComponent?: React.FunctionComponent<IDialogProps>;
  primaryDialogForm?: string | DialogFormGenerateFunction;
  primaryInitialSelectedId?: number;

  secondaryModel: IModel;
  secondaryTitleToken: string;
  secondaryView: string | undefined;
  secondaryAllowView?: boolean;
  secondaryAllowEdit?: boolean;
  secondaryDialogComponent?: React.FunctionComponent<IDialogProps>;
  secondaryDialogForm?: string | DialogFormGenerateFunction;

  baseRecordPath: string;
  childRecordRelativePath: string;
  geomRelativePath: string;

  mapId: number;
  mapModel: IModel;

  allowShowImages?: boolean;
  imageRelativePath?: string;
  modelPhotos?: IModel;

  onCustomTableAction?: RowActionFn;

  // Open page as dialog
  primaryDialogAsPagePath?: string | undefined;
};

const DataCentricPage = (props: DataCentricPageParams) => {
  const [selectedPrimaryRecordId, setSelectedPrimaryRecordId] = useState(
    props.primaryInitialSelectedId || 0
  );
  const [selectedSecondaryRecordId, setSelectedSecondaryRecordId] = useState(0);
  const [showImages, setShowImages] = useState(false);
  const [images, setImages] = useState<DCRecord[]>([]);
  const [imagesButtonEnabled, setImagesButtonEnabled] = useState(false);
  const [refreshSecondaryrecords, setRefreshSecondaryRecords] = useState(false);

  const [alternateView, setAlternateView] = useState(false);

  const windowSize = useWindowSize();

  const mapRef = useRef<HTMLDivElement>(null);
  const primaryRef = useRef<HTMLDivElement>(null);

  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  const {
    primaryModel,
    primaryView,
    primaryTitleToken,
    primaryAllowAdd,
    primaryAllowView,
    primaryAllowEdit,
    primaryDialogComponent,
    primaryDialogForm,
    primaryInitialSelectedId,
    secondaryModel,
    secondaryView,
    secondaryTitleToken,
    secondaryAllowView,
    secondaryAllowEdit,
    secondaryDialogComponent,
    secondaryDialogForm,
    mapId,
    mapModel,
    baseRecordPath,
    childRecordRelativePath,
    geomRelativePath,
    allowShowImages,
    imageRelativePath,
    modelPhotos,
    primaryDialogAsPagePath,
    onCustomTableAction,
  } = props;

  const handlePrimaryRecordSelect = (id: number) => {
    if (id === selectedPrimaryRecordId) {
      setSelectedPrimaryRecordId(0);
    } else if (id !== 0 && id !== null) {
      setSelectedPrimaryRecordId(id);
      scrollIntoMap();
    }
  };

  const scrollIntoMap = () => {
    if (!mdUp && mapRef.current) {
      mapRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }

  const handleMapResize = () => {
    setAlternateView(prev => !prev);
  }

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'l' && event.altKey) {
        handleMapResize();
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const scrollIntoPrimary = () => {
    if (!mdUp && primaryRef.current) {
      primaryRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }

  const handleSecondaryRecordSelect = (id: number) => {
    if (id !== selectedSecondaryRecordId && id !== 0 && id !== null) {
      setSelectedSecondaryRecordId(id);
    }
  };

  const handlePrimaryRecordsLoad = (records: Array<DCRecord>) => {
    setRefreshSecondaryRecords(true);
  };

  const handleSecondaryRecordsLoad = (records: Array<DCRecord>) => {};

  const fetchImages = () => {
    if (selectedPrimaryRecordId !== 0 && modelPhotos && imageRelativePath) {
      const dc = new dataController(modelPhotos);
      const customPath = `${baseRecordPath}/${selectedPrimaryRecordId}/${imageRelativePath}`;
      dc.GetData(customPath)
        .then((resp) => {
          if (resp.success && resp.data) {
            if (Array.isArray(resp.data)) {
              setImages(resp.data);
              setImagesButtonEnabled(true);
            } else {
              setImages([]);
              setImagesButtonEnabled(false);
            }
          }
        })
        .catch((resp) => {
          setImages([]);
          setImagesButtonEnabled(false);
        });
    }
  };

  useEffect(() => {
    if (primaryInitialSelectedId) {
      setSelectedPrimaryRecordId(primaryInitialSelectedId);
    }
  }, [primaryInitialSelectedId]);

  useEffect(() => {
    fetchImages()
    scrollIntoPrimary()
  }, [selectedPrimaryRecordId]);

  const setAllowImages = () => {
    setShowImages(!showImages);
  };

  const upperPartHeight = windowSize.height
    ? `${Math.floor((windowSize.height - 52) * 0.6)}px`
    : '60%';
  const lowerPartHeight = windowSize.height
    ? `${Math.floor((windowSize.height - 52) * 0.4)}px`
    : '40%';

    return !(mdUp && alternateView) ? (
    <Grid container columns={24} height={'calc( 100vh - 48px )'}>
     <Grid item xs={24}  sx={{ maxHeight: '60%', minHeight: {xs: allowShowImages && showImages ? '60%' : '0', md: '60%'} }} position={'relative'}>
        <div style={{width: "100%", height: "100%"}} ref={primaryRef}>
          <DataCentricPrimaryTable
            model={primaryModel}
            titleToken={primaryTitleToken}
            view={primaryView}
            onLoad={handlePrimaryRecordsLoad}
            onRowAction={onCustomTableAction}
            onRecordSelect={handlePrimaryRecordSelect}
            selectedRecordId={selectedPrimaryRecordId}
            setAllowImages={setAllowImages}
            imagesEnabled={imagesButtonEnabled}
            allowAdd={primaryAllowAdd}
            allowView={primaryAllowView}
            allowEdit={primaryAllowEdit}
            dialogComponent={primaryDialogComponent}
            dialogForm={primaryDialogForm}
            dialogAsPagePath={primaryDialogAsPagePath}
          />
          {allowShowImages && showImages ? (
            <DataCentricImagesOverlay
              closeOverlay={() => setShowImages(false)}
              images={images}
              selectedObjektId={selectedPrimaryRecordId}
            />
          ) : null}
        </div>
      </Grid>
    
      <Grid  item sx={{ height: "40%" }} xs={24} md={9} lg={7} xl={5}>
        <div ref={mapRef} style={{width: "100%", height: "100%"}}>
          <DataCentricMiniMap
            onPrimaryRecordSelect={handlePrimaryRecordSelect}
            selectedPrimaryRecordId={selectedPrimaryRecordId}
            model={mapModel}
            mapId={mapId}
            baseRecordPath={baseRecordPath}
            geomRelativePath={geomRelativePath}
            isResized={alternateView}
            onResize={handleMapResize}
          />
        </div>
      </Grid>
      <Grid item sx={{ maxHeight: {xs:'60%',md:'40%'}, minHeight: {xs: '0', md: '40%'}}} xs={24} md={15} lg={17} xl={19}>
        <DataCentricSecondaryTable
          selectedPrimaryRecordId={selectedPrimaryRecordId}
          onRecordSelect={handleSecondaryRecordSelect}
          onLoad={handleSecondaryRecordsLoad}
          onRowAction={() => {}}
          view={secondaryView}
          model={secondaryModel}
          titleToken={secondaryTitleToken}
          baseRecordPath={baseRecordPath}
          childRecordRelativePath={childRecordRelativePath}
          setAllowImages={setAllowImages}
          imagesEnabled={imagesButtonEnabled}
          triggerRefresh={refreshSecondaryrecords}
          setTriggerRefresh={setRefreshSecondaryRecords}
          allowView={secondaryAllowView}
          allowEdit={secondaryAllowEdit}
          dialogComponent={secondaryDialogComponent}
          dialogForm={secondaryDialogForm}
        />
      </Grid>
    </Grid>
  ) : (
    <PanelGroup direction="horizontal">
      <Panel defaultSize={40} minSize={20}>
        <div ref={mapRef} style={{width: "100%", height: "100%"}}>
          <DataCentricMiniMap
            onPrimaryRecordSelect={handlePrimaryRecordSelect}
            selectedPrimaryRecordId={selectedPrimaryRecordId}
            model={mapModel}
            mapId={mapId}
            baseRecordPath={baseRecordPath}
            geomRelativePath={geomRelativePath}
            isResized={alternateView}
            onResize={handleMapResize}
          />
        </div>
      </Panel>
      <StyledPanelResizeHandle variant='vertical'/>
      <Panel defaultSize={60} minSize={20}>
        <PanelGroup direction="vertical">
          <Panel defaultSize={50} minSize={20}>
            <div style={{width: "100%", height: "100%"}} ref={primaryRef}>
              <DataCentricPrimaryTable
                model={primaryModel}
                titleToken={primaryTitleToken}
                view={primaryView}
                onLoad={handlePrimaryRecordsLoad}
                onRowAction={onCustomTableAction}
                onRecordSelect={handlePrimaryRecordSelect}
                selectedRecordId={selectedPrimaryRecordId}
                setAllowImages={setAllowImages}
                imagesEnabled={imagesButtonEnabled}
                allowAdd={primaryAllowAdd}
                allowView={primaryAllowView}
                allowEdit={primaryAllowEdit}
                dialogComponent={primaryDialogComponent}
                dialogForm={primaryDialogForm}
                dialogAsPagePath={primaryDialogAsPagePath}
              />
              {allowShowImages && showImages ? (
                <DataCentricImagesOverlay
                  closeOverlay={() => setShowImages(false)}
                  images={images}
                  selectedObjektId={selectedPrimaryRecordId}
                />
              ) : null}
            </div>
          </Panel>
          <StyledPanelResizeHandle variant='horizontal'/>
          <Panel defaultSize={50} minSize={10}>
            <DataCentricSecondaryTable
              selectedPrimaryRecordId={selectedPrimaryRecordId}
              onRecordSelect={handleSecondaryRecordSelect}
              onLoad={handleSecondaryRecordsLoad}
              onRowAction={() => {}}
              view={secondaryView}
              model={secondaryModel}
              titleToken={secondaryTitleToken}
              baseRecordPath={baseRecordPath}
              childRecordRelativePath={childRecordRelativePath}
              setAllowImages={setAllowImages}
              imagesEnabled={imagesButtonEnabled}
              triggerRefresh={refreshSecondaryrecords}
              setTriggerRefresh={setRefreshSecondaryRecords}
              allowView={secondaryAllowView}
              allowEdit={secondaryAllowEdit}
              dialogComponent={secondaryDialogComponent}
              dialogForm={secondaryDialogForm}
            />
          </Panel>
        </PanelGroup>
      </Panel>
    </PanelGroup>
  );
};

export default DataCentricPage;
