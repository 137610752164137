import { IModel } from '@/@types/models/model';
import coreFields from './helpers/coreFields';

const Documents: IModel = {
  title: 'Documents',
  apiPath: 'core/documents-for-entity',
  forms: {
    default: {
      fields: ['files'],
    },
  },
  listViews: {
    default: {
      fields: ['gid', 'files'],
    },
  },
  fields: [
    coreFields.id,
    coreFields.gid,
    {
      title: 'files',
      source: 'files',
      ttoken: 'documents.files',
      type: 'document',
      subModel: {
        apiPath: "core/documents",
        format: ""
      },
      maxFiles: 5,
    },
  ],
};

export default Documents;

