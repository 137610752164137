import {
  grey,
  lightBlue,
  lightGreen,
  orange,
  purple,
  red,
  yellow,
} from '@mui/material/colors';

function hash(str: string) {
  // one of primes [31, 33, 37, 39, and 41] will produce less than 7 collisions if
  // 		we take over 50000 English words
  const H = 37;
  let total = 0;

  for (var i = 0; i < str.length; i++) {
    total += H * total + str.charCodeAt(i);
  }
  total %= 1002569; // arbitrary prime number -- so we do not get some huge values

  return parseInt(String(total));
}

function retrieveIconColor(icon: string): string | undefined {
  switch (icon) {
    case 'drafts':
      return yellow['700'];
    case 'send':
      return lightBlue['500'];
    case 'hourglass_empty':
      return grey['500'];
    case 'thumb_up':
      return lightGreen['500'];
    case 'thumb_down':
      return red['500'];
    case 'help':
      return orange['500'];
    case 'assignment_turned_in':
      return lightGreen['500'];
    case 'commute':
      return purple['500'];
    case 'home':
      return lightGreen['500'];
    case 'archive':
      return orange['500'];
    case 'warning':
      return red['500'];
    case 'remove_circle':
      return red['500'];
    default:
      return undefined;
  }
}

function createUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function isArrayOfNumbersLength2(value: any): value is [number, number] {
  return (
    Array.isArray(value) &&
    value.length === 2 &&
    typeof value[0] === 'number' &&
    typeof value[1] === 'number'
  );
}

function isArrayOfNumbers(value: any): value is number[] {
  return (
    Array.isArray(value) && value.every((item) => typeof item === 'number')
  );
}

export const b64toBlob = (
  b64Data: string,
  contentType = '',
  sliceSize = 512
) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const helpers = {
  hash,
  retrieveIconColor,
  createUUID,
  isArrayOfNumbers,
  isArrayOfNumbersLength2,
  b64toBlob,
};
