import React, { useContext } from "react";

// Context
import DialogContext from '@/context/DialogContext/DialogContext';

// Custom
import AttachedDocumentDialog from '@/views/core/Documents/AttachedDocumentDialog';
import useApi from "@/lib/api/useApi";
import { DCRecord } from "@/@types/lib/dataController";

import { DCFile } from '@/@types/lib/dataController';
import { ClosingDetails } from "@/@types/components/authFormController";

type handleCloseFn = (result: ClosingDetails) => void;

export interface IDocuments {
  checkIfHasGid: (records: DCRecord[]) => boolean;
  showDocumentsDialog: (gid: string, onClose?: handleCloseFn) => void;
  downloadDocument: (gid: string) => void;
  saveDocument: (file: DCFile) => void;
}

// Hook
function useDocuments(): IDocuments {

  const { showDialog } = useContext(DialogContext);

  const checkIfHasGid = (records: DCRecord[]) => {
    return records && records.length > 0 && records[0].hasOwnProperty("gid") === true ? true : false;
  }

  const showDocumentsDialog = (gid: string, onClose?: handleCloseFn) => {
    showDialog(
      AttachedDocumentDialog,
      {
        // dc,
        mode: 'update',
        form: 'insert',
        dataId: gid,
        onClose: onClose,
      },
      2
    );
  }

  const downloadDocument = (gid: string) => {

    const apiInstance = useApi();
    apiInstance.downloadWithMetadata("core/documents", gid).then((resp) => {
      if (resp.success && resp.data) {

        const file = resp.data;

        if (!file.content) {
          console.error("File has no content")
        } else {
          saveDocument(file);
        }
      }
    });
  };

  const saveDocument = (file: DCFile) => {
    if (file.content) {
      const byteCharacters = atob(file.content);
      const byteNumbers = new Array(byteCharacters.length).fill(undefined).map((_, i) => byteCharacters.charCodeAt(i));
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: file.type });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = file.name;
      link.click();
    }
  }

  return {
    checkIfHasGid,
    showDocumentsDialog,
    downloadDocument,
    saveDocument
  }

}

export default useDocuments