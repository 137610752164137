import React, { useContext, useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

// d.ts
import Tooltip from '@mui/material/Tooltip';
import { DCRecord } from '@/@types/lib/dataController';

// Material UI Core

// Custom Components
import ModelTable from '@/ui/Table/ModelTable';
import DialogContext, {
  DialogContextType,
} from '@/context/DialogContext/DialogContext';

import DataController, { IDataController } from '@/lib/DataController';

// Types
import { IHeaderData, IImageData } from '@/@types/views/KomunalniObveznici';
import { IModel } from '@/@types/models/model';
import { BackgroundColorObj } from '@/@types/ui/Table/table';

// TODO:
// subModels cleanup: they are added to the project because Table.jsx can not function without them

interface IEnaDataCentricSecondaryTable {
  lokacijaId: number;
  selectedObjektId: number;
  onObjektSelect: (id: number) => void;
  primaryRecord: DCRecord | null;
  setImgData: (record: IImageData) => void;
  onLoad?: (records: Array<DCRecord>) => void;

  model: IModel;
  titleToken: string;

  baseRecordPath: string;
  childRecordRelativePath: string;

  disableControls?: boolean;
}

type MemoColorType = {
  [key: number]: string;
};

const EnaDataCentricSecondaryTable = (props: IEnaDataCentricSecondaryTable) => {

  const colors = ['#fafafa', '#dadada'];

  const dialogContext = useContext<DialogContextType>(DialogContext);
  const { t } = useTranslation();
  const {
    lokacijaId,
    selectedObjektId,
    onObjektSelect,
    primaryRecord,
    setImgData,
    onLoad,
  } = props;
  const {
    model,
    titleToken,
    baseRecordPath,
    childRecordRelativePath,
    disableControls,
  } = props;

  const [records, setRecords] = useState<DCRecord[]>([]);
  const [displayRecords, setDisplayRecords] = useState<DCRecord[]>([]);

  const dc: IDataController = new DataController(model);

  useEffect(() => {
    setRecords([]);
    if (lokacijaId !== 0) {
      refreshRecords();
    }
  }, [lokacijaId]);

  useEffect(() => {
    if (records.length && lokacijaId) {
      setDisplayRecords(records);
    } else {
      setDisplayRecords([]);
    }
  }, [records, lokacijaId]);

  const refreshRecords = () => {
    const path = `${baseRecordPath}/${lokacijaId}/${childRecordRelativePath}`;
    dc.GetData(path).then((resp) => {
      if (resp.success && Array.isArray(resp.data)) {
        const objs: IImageData = {};
        resp.data.forEach((d) => {
          const id = d.id as number;
          if (id && id in objs) {
            if (d.kat) {
              objs[id].kat = Math.max(objs[id].kat, d.kat as number);
            }
            if (d.ukupno) {
              objs[id].ukupno += d.ukupno as number;
            }
          } else if (d.id) {
            objs[id] = {
              kat: d.kat ? (d.kat as number) : 0,
              ukupno: d.ukupno ? (d.ukupno as number) : 0,
            };
          }
        });
        setImgData(objs);
        const rs = resp.data.map((r) => {
          if (r && r.id) {
            r.obj_id = (r.id as number) % 1_000_000;
          }
          return r;
        });
        setRecords(rs);
        if (onLoad) {
          onLoad(rs);
        }
      }
    });
  };

  function getTooltip() {
    return primaryRecord !== null ? (
      <>
        <div>{`${t('komunalni_obveznici.tt.objekti')}: ${
          primaryRecord.lok
        }`}</div>
        <div>{`${t('komunalni_obveznici.tt.adresa')}: ${
          primaryRecord.adrobj
        }`}</div>
        <div>{`${t('komunalni_obveznici.tt.obveznik')}: ${
          primaryRecord.obv
        }`}</div>
      </>
    ) : (
      ''
    );
  }

  function getSubTitle(): React.ReactNode {
    return lokacijaId !== 0 ? (
      <Tooltip
        title={getTooltip()}
        TransitionProps={{ style: { maxWidth: '500px' } }}
      >
        <div style={{ display: 'inline-block' }}>
          {(primaryRecord ? primaryRecord.adrobj : '') as string}
          {primaryRecord && primaryRecord.kc
            ? `, ${t('komunalni_obveznici.kc')}: ${primaryRecord.kc}`
            : ''}
          {primaryRecord && primaryRecord.ko
            ? `, ${t('komunalni_obveznici.ko')}: ${primaryRecord.ko}`
            : ''}
        </div>
      </Tooltip>
    ) : null;
  }

  function getRowTooltipTitle(original: {
    [key: string]: any;
  }): React.ReactNode[] {
    const fields = dc.getViewFields('tooltip');
    return fields.map((f, i) => {
      if (!(f.source in original) || f.source === 'kat') {
        return null;
      }

      let ttTitle = `${t(f.tooltip ?? '')}: ${original[f.source]}`;
      if (f.source === 'obj_id') {
        const fKat = fields.find((ff) => ff.source === 'kat');
        if (fKat && fKat.tooltip) {
          ttTitle += ` (${t(fKat.tooltip ?? '')} ${
            fKat.source in original ? original[fKat.source] : ''
          })`;
        }
      }
      return <div key={i}>{ttTitle}</div>;
    });
  }

  function getRowTooltip(
    children: React.ReactNode,
    original?: { [key: string]: any }
  ): React.ReactNode {
    return (
      <Tooltip
        placement="bottom-start"
        title={getRowTooltipTitle(original || {})}
      >
        {children as React.ReactElement}
      </Tooltip>
    );
  }

  const getRowStyle = (id: number) => {
    return { backgroundColor: selectedObjektId===id?colors[1]:colors[0]} as BackgroundColorObj;
  }

  const handleRowClick = (evt: React.SyntheticEvent<Element, Event>, selection: {[key: string]: DCRecord}) => {
    //console.log(selection[Object.keys(selection)[0]].id as number)
    onObjektSelect(selection[Object.keys(selection)[0]].id as number)
  }

  return (
    <ModelTable
      allowSearch={false}
      title={t(titleToken)}
      // subTitle={getSubTitle()}
      // RowTooltip={({ children, original }) => getRowTooltip(children, original)}
      records={displayRecords}
      // initialPageSize={records.length}
      dc={dc}
      allowSelection="one"
      allowFilter={false}
      allowExport={false}
      allowAdd={false}
      // allowEdit={false}
      // allowSearchBar={false}
      // disableControls={disableControls !== undefined ? disableControls : true}
      onRowAction={() => {}}
      allowRowAction={() => true}
      rowSelectedCustom={selectedObjektId}
      onRowClick={handleRowClick}
      // trackTableState={false}
      // unselectRow
      getRowStyle={getRowStyle}
    />
  );
};

export default EnaDataCentricSecondaryTable;
