import * as React from 'react';

import AppPage from '@/ui/AppPage/AppPage';
import UserTenantForm from './UserTenantForm';
import Box from '@mui/material/Box';
import Permissions from '@/lib/permissions';

import { useParams } from 'react-router-dom';
import dataController from '@/lib/DataController';
import model from '@/models/user_tenant';

function UserTenantPage() {
  const { id } = useParams();
  const dc = new dataController(model);

  const recordId = parseInt(id ? id : '', 10);

  return (
    <AppPage titlett="page.user" needPermission={[Permissions.TenantUserAdministration]}>
      <Box m={2}>
        <UserTenantForm dc={dc} recordId={recordId} mode="update" form="update" />
      </Box>
    </AppPage>
  );
}

export default UserTenantPage;
