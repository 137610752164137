import * as React from 'react';

import Map from 'ol/Map';
import OlBaseLayer from 'ol/layer/Base';
import Geometry from 'ol/geom/Geometry';
import { Point } from 'ol/geom';
import LayerGroup from 'ol/layer/Group';
import Control from 'ol/control/Control';
import Interaction from 'ol/interaction/Interaction';
import OlBaseEvent from 'ol/events/Event';
import OlCollection from 'ol/Collection';
import { getLength, getArea } from "ol/sphere";

import LineString from 'ol/geom/LineString';
import Polygon from 'ol/geom/Polygon';
import MultiPolygon from 'ol/geom/MultiPolygon';

import { MapContextType } from '@/context/MapContext/MapContext';
import { FunctionComponent, ReactElement, ReactNode, useContext } from "react";
import {Circle as CircleStyle, Stroke, Style} from 'ol/style.js';
import {easeOut} from 'ol/easing.js';
import {getVectorContext} from 'ol/render.js';


export {
  // flatLayersCollection,
  // flatLayersArray,
  formatLength,
  formatArea,
  formatPoint,
  flattenLayers,
  findLayer,
  findChild,
  getOnlyGroupLayers,
  getCallDirectLayers,
  getCallGroupedLayers,
  getEvents,
  getDefinedOptions,
  registerOlControl,
  findControl,
  registerOlInteraction,
  findInteraction,
  calcAngle,
  padExtent,
  registerSource
};

const idKey = 'id';

function findLayer(map: Map, id: any): OlBaseLayer | null {
  let foundLayer = null;

  if (map) {
    const mapRootLayers = map.getLayers().getArray();

    //find layer in root level
    const mapLayer = mapRootLayers.find((x) => x.get(idKey) === id);
    if (mapLayer) {
      foundLayer = mapLayer;
      return foundLayer;
    }

    //check in GroupLayers
    mapRootLayers
      .filter((x) => x instanceof LayerGroup)
      .forEach((groupLayer) => {
        const layer = _findInTree(groupLayer, id);
        if (layer) {
          foundLayer = layer;
        }
      });
  }

  return foundLayer;
}

function _findInTree(
  topLayer: any,
  id: string
): OlBaseLayer | null | undefined {
  if (topLayer instanceof LayerGroup) {
    const childLayers = topLayer.getLayers().getArray();
    const l = childLayers.find((x) => x.get(idKey) === id);
    if (l) {
      return l;
    } else {
      return childLayers
        .filter((x) => x instanceof LayerGroup)
        .map((x) => _findInTree(x, id))
        .find((x) => x !== null && x !== undefined);
    }
  } else if (topLayer.get(idKey) === id) {
    return topLayer;
  } else {
    return null;
  }
}

// function flatLayersCollection(olLayerCollection, steps) {
//   const arr = olLayerCollection.getArray();
//   return flattenLayers(arr, steps);
// }
//
// function flatLayersArray(layers, steps) {
//   return flattenLayers(layers, steps);
// }

function flattenLayers(arr: any[] | null, d = 1): OlBaseLayer[] | [] {
  if (arr) {
    return d > 0
      ? arr.reduce(
          (acc, val) =>
            acc.concat(
              val instanceof LayerGroup && val.getLayers().getArray().length > 0
                ? //@ts-ignore TODO:
                  [val].concat(flattenLayers(val.getLayers().getArray(), d - 1))
                : val
            ),
          []
        )
      : arr.slice();
  } else {
    return [];
  }
}

function flattenControls(arr: Control[] | null, d = 1): Control[] {
  if (arr) {
    return d > 0
      ? arr.reduce(
          (acc, val) =>
            acc.concat(
              //@ts-ignore
              typeof val.getControls === 'function'
                ? //@ts-ignore
                  [val].concat(flattenControls(val.getControls(), d - 1))
                : val
            ),
          []
        )
      : arr.slice();
  } else {
    return [];
  }
}

function formatLength(line: LineString): string {
  const length = getLength(line);
  if (length > 500) {
    return Math.round((length / 1000) * 100) / 100 + " " + "km";
  } else {
    return Math.round(length * 100) / 100 + " " + "m";
  }
}

function formatArea(polygon: Polygon | MultiPolygon): string {
  const area = getArea(polygon);
  if (area > 10000) {
    return Math.round((area / 10000) * 100) / 100 + " ha";
  } else {
    return Math.round(area * 100) / 100 + " m²";
  }
}

function formatPoint(point: Point): string {
  const _pointCoords = point.getCoordinates();
  return (
    _pointCoords[0].toFixed(0).toString() +
    ', ' +
    _pointCoords[1].toFixed(0).toString()
  );
}

function findControl(map: Map, id: any, Control: any): Control | undefined {
  const flatControls = flattenControls(map.getControls().getArray());
  if (id) {
    return flatControls.find((x) => x.get('id') === id);
  } else {
    return flatControls.find((x) => x instanceof Control);
  }
}

function getOnlyGroupLayers(arr: OlBaseLayer[]) {
  const res = arr.filter((x) => x instanceof LayerGroup) as LayerGroup[];
  return res;
}

function getCallDirectLayers(arr: OlBaseLayer[]) {
  const res = arr
    .filter((x) => !(x instanceof LayerGroup))
    .filter(
      (x) => x.get('call_group') === undefined || x.get('call_group') === null
    );
  return res;
}

function getCallGroupedLayers(arr: OlBaseLayer[]) {
  let dict: { [key: string]: OlBaseLayer[] } = {};

  arr
    .filter((x) => !(x instanceof LayerGroup))
    .forEach((l) => {
      const call_group: string = l.get('call_group');
      if (call_group) {
        if (dict.hasOwnProperty(call_group)) {
          dict[call_group] = dict[call_group].concat([l]);
        } else {
          dict[call_group] = [l];
        }
      }
    });

  const res = Object.keys(dict).map((key) => ({
    call_group: key,
    layers: dict[key],
  }));
  return res;
}

//Controls
function registerOlControl(
  context: MapContextType,
  Control: any,
  props: any,
  options: object,
  events: object
): () => void {
  let allOptions = Object.assign(options, props);
  let definedOptions = getDefinedOptions(allOptions);

  let control = new Control(definedOptions);

  if (props.id) {
    control.set('id', props.id);
  }

  if (context.map) {
    const mapControl = findControl(context.map, props.id, Control);
    if (mapControl) {
      context.map.removeControl(mapControl);
      // console.log('control removed', Control);
    }
    context.map.addControl(control);
    // console.log('control added', Control);
  } else {
    context.initOptions.controls.push(control);
  }

  let olEvents = getEvents(events, props);
  for (let eventName in olEvents) {
    //@ts-ignore
    control.on(eventName, olEvents[eventName]);
  }

  return () => {
    if (context.map) {
      const mapControl = findControl(context.map, props.id, Control);
      if (mapControl) {
        context.map.removeControl(mapControl);
      }
    }
  };
}

function getDefinedOptions(props: object): object {
  let options = {};
  for (let key in props) {
    if (
      key !== 'children' &&
      //@ts-ignore
      typeof props[key] !== 'undefined' && //exclude undefined ones
      !key.match(/^on[A-Z]/) //exclude events
    ) {
      //@ts-ignore
      options[key] = props[key];
    }
  }
  return options;
}

function getPropsKey(eventName: string): string {
  return (
    'on' +
    eventName
      .replace(/(\:[a-z])/g, ($1) => $1.toUpperCase())
      .replace(/^[a-z]/, ($1) => $1.toUpperCase())
      .replace(':', '')
  );
}

function getEvents(
  events: object = {},
  props: object = {}
): { [key: string]: OlBaseEvent } | null {
  let prop2EventMap = {};
  for (let key in events) {
    //@ts-ignore
    prop2EventMap[getPropsKey(key)] = key;
  }

  let ret = null;
  for (let propName in props) {
    //@ts-ignore
    let eventName = prop2EventMap[propName];
    //@ts-ignore
    let prop = props[propName];
    if (
      typeof prop !== 'undefined' &&
      propName.match(/^on[A-Z]/) &&
      eventName
    ) {
      if (ret === null) {
        ret = {};
      }
      //@ts-ignore
      ret[eventName] = prop;
    }
  }

  return ret;
}

// let typeOf = function(obj){
//   return ({}).toString.call(obj)
//     .match(/\s([a-zA-Z]+)/)[1].toLowerCase();
// };
//
// function cloneObject(obj){
//   var type = typeOf(obj);
//   if (type == 'object' || type == 'array') {
//     if (obj.clone) {
//       return obj.clone();
//     }
//     var clone = type == 'array' ? [] : {};
//     for (var key in obj) {
//       clone[key] = cloneObject(obj[key]);
//     }
//     return clone;
//   }
//   return obj;
// }

function findChild(
  children: ReactNode,
  childType: FunctionComponent<any>
): ReactElement | null | {} {
  let found = null;
  let childrenArr = React.Children.toArray(children);
  for (let i = 0; i < childrenArr.length; i++) {
    let child = childrenArr[i];
    //@ts-ignore TODO: No type attribute on child
    if (child.type == childType) {
      found = child;
      break;
    }
  }
  return found;
}

//Interactions
function findInteraction(map: Map, Interaction: any): Interaction | undefined {
  return map
    .getInteractions()
    .getArray()
    .find((x) => x instanceof Interaction);
}

function registerOlInteraction(
  context: MapContextType,
  Interaction: any,
  props: any,
  options: object,
  events: object
): () => void {
  let allOptions = Object.assign(options, props);
  let definedOptions = getDefinedOptions(allOptions);

  let interaction = new Interaction(definedOptions);
  if (context.map) {
    const mapInteraction = findInteraction(context.map, Interaction);
    if (mapInteraction) {
      context.map.removeInteraction(mapInteraction);
    }
    context.map.addInteraction(interaction);
  } else {
    context.initOptions.interactions.push(interaction);
  }

  let olEvents = getEvents(events, props);
  for (let eventName in olEvents) {
    //@ts-ignore TODO: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'object | {}'
    interaction.on(eventName, olEvents[eventName]);
  }

  return () => {
    //happens on umount
    if (context.map) {
      const mapInteraction = findInteraction(context.map, Interaction);
      if (mapInteraction) {
        context.map.removeInteraction(mapInteraction);
      }
    }
  };
}

function calcAngle(p1: [number, number], p2: [number, number]) {
  const x = p2[0] - p1[0];
  const y = p2[1] - p1[1];

  return Math.atan2(x, y);
  //reutrn Math.atan2(x, y) * 180 / Math.PI;
}

function padExtent(extent: number[] | undefined, padding: number = 10): number[] {
  if (!extent || extent.length === 0) {
    return [];
  }
  if (extent.length !== 4) {
    console.error("Expected extent to be length 4 but got: " + extent.length)
  }
  padding = padding / 100;
  const dx = extent[2] - extent[0];
  const dy = extent[3] - extent[1];

  const paddedExtent = new Array(4).fill(0);
  paddedExtent[0] = extent[0] - dx * padding;
  paddedExtent[1] = extent[1] - dy * padding;
  paddedExtent[2] = extent[2] + dx * padding;
  paddedExtent[3] = extent[3] + dy * padding;
  return paddedExtent;
}

const duration = 3000;
function flash(feature: any) {
  const start = Date.now();
  const flashGeom = feature.getGeometry().clone();
  // const listenerKey = tileLayer.on('postrender', animate);

  function animate(event: any) {
    const frameState = event.frameState;
    const elapsed = frameState.time - start;
    if (elapsed >= duration) {
      // unByKey(listenerKey);
      return;
    }
    const vectorContext = getVectorContext(event);
    const elapsedRatio = elapsed / duration;
    // radius will be 5 at start and 30 at end.
    const radius = easeOut(elapsedRatio) * 25 + 5;
    const opacity = easeOut(1 - elapsedRatio);

    const style = new Style({
      image: new CircleStyle({
        radius: radius,
        stroke: new Stroke({
          color: 'rgba(255, 0, 0, ' + opacity + ')',
          width: 0.25 + opacity,
        }),
      }),
    });

    vectorContext.setStyle(style);
    vectorContext.drawGeometry(flashGeom);
    // tell OpenLayers to continue postrender animation
    // mapContext.map?.render();
  }
}

function registerSource(source: any) {
  source.on('change', function (e: any) {
    flash(e.feature);
  });
}


