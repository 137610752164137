import React, { useContext } from 'react';

// Openlayers
import { transform } from 'ol/proj';

// Custom Components
import Permissions from '@/lib/permissions';
import AppPage from '@/ui/AppPage/AppPage';

// Models
import modelKuceZaOdmor from '@/models/kuce_za_odmor';

// Types
import DataCentricPage from '@/ui/DataCentric/DataCentricPage';
import UserContext from '@/context/UserContext/UserContext';

type KuceZaOdmorDataCentricPageParams = {};

const KuceZaOdmorDataCentricPage = (
  props: KuceZaOdmorDataCentricPageParams
) => {
  const userContext = useContext(UserContext);

  const handleCustomAction = (
    action: string,
    id: number,
    record?: { [key: string]: any }
  ) => {
    let locationId: number | null = null;
    if (record && 'lokacija_id' in record) {
      locationId = record.lokacija_id as number;
    }
    let objektId: number | null = null;
    if (record && 'objekt_id' in record) {
      objektId = record.objekt_id as number;
    }
    switch (action) {
      case 'show_object':
        if (id) {
          const locId = locationId === null ? '' : `&loc_id=${locationId}`;
          const url = `${
            location.origin
          }/ena/utility/?obj_id=${id.toString()}&open_first=true${locId}`;
          window.open(url, '_blank');
        }
        break;
      case 'show_3d':
        if (record) {
          const [cx, cy] = transform(
            [record?.cx, record?.cy],
            'EPSG:3765',
            'EPSG:4326'
          ).map((x) => (x * Math.PI) / 180);

          // navigate("/3d", { state: { cx: cx, cy: cy, cz: record?.cz}}) //(opens in the same tab)
          const url = `${
            location.origin
          }/3d/?cx=${cx.toString()}&cy=${cy.toString()}&cz=${record?.cz.toString()}`;
          window.open(url, '_blank'); // opens in a new tab
        }
        break;
      case 'show_photos':
        if (id) {
          const objId = objektId === null ? '' : `?rec_id=${objektId}`;
          const url = `${
            location.origin
          }/ena/photodoc/${objId}`;
          window.open(url, '_blank');
        }
        break;
      default:
        break;
    }
  };

  const viewName = userContext?.hasAnyPermission([Permissions.ThreeDView])
    ? 'default_with_3d'
    : 'default';

  return (
    <AppPage
      titlett="ena:page.kuce_za_odmor"
      needPermission={[Permissions.EnaKuceZaOdmor]}
    >
      <DataCentricPage
        primaryModel={modelKuceZaOdmor}
        primaryTitleToken="ena:titles.kuce_za_odmor"
        primaryView={viewName}
        secondaryModel={modelKuceZaOdmor}
        secondaryTitleToken="ena:titles.kuce_za_odmor_lokacija"
        secondaryView="secondary"
        baseRecordPath={modelKuceZaOdmor.apiPath}
        childRecordRelativePath="other"
        geomRelativePath=""
        mapId={25}
        mapModel={modelKuceZaOdmor}
        onCustomTableAction={handleCustomAction}
      />
    </AppPage>
  );
};

export default KuceZaOdmorDataCentricPage;
