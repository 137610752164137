// These are core fields that are present in most of the tables in DB
// They can be added to UI models...

import {
  IFieldID,
  IFieldGID,
  IFieldText,
  IFieldDateTime,
  IFieldPickerItems,
  IFieldWKT,
  // IFieldDocument,
  IFieldFile,
} from '@/@types/models/model';

const id: IFieldID = {
  title: 'ID',
  source: 'id',
  ttoken: 'common.id',
  type: 'text',
  filter: true,
  readonly: true,
  idattr: true,
  export: {
    width: 15,
  },
};

const gid: IFieldGID = {
  title: 'GID',
  source: 'gid',
  ttoken: 'common.gid',
  type: 'text',
  filter: true,
  readonly: true,
};

const active: IFieldPickerItems = {
  title: 'Active',
  source: 'active',
  ttoken: 'common.active',
  type: 'active',
  filter: true,
  items: {
    labels: ['common.no', 'common.yes'],
    values: [false, true],
  },
  readonly: true,
  translate: true,
};

const created_on: IFieldDateTime = {
  title: 'Created On',
  source: 'created_on',
  ttoken: 'common.created_on',
  type: 'datetime',
  filter: true,
  readonly: true,
  export: {
    width: 20,
  },
};

const created_by: IFieldText = {
  title: 'Created By',
  source: 'created_by',
  ttoken: 'common.created_by',
  type: 'text',
  filter: true,
  readonly: true,
};

const modified_on: IFieldDateTime = {
  title: 'Modified On',
  source: 'modified_on',
  ttoken: 'common.modified_on',
  type: 'datetime',
  filter: true,
  readonly: true,
  export: {
    width: 20,
  },
};

const modified_by: IFieldText = {
  title: 'Modified By',
  source: 'modified_by',
  ttoken: 'common.modified_by',
  type: 'text',
  filter: true,
  readonly: true,
};

const geom: IFieldWKT = {
  title: 'Geometry',
  source: 'geom',
  ttoken: 'common.geometry',
  type: 'wkt',
  readonly: true,
};

const docs: IFieldFile = {
  title: 'Dokumenti',
  source: 'docs',
  ttoken: 'common.documents',
  type: 'document',
  readonly: true,
  subModel: {
    apiPath: 'core/documents',
    format: '{name}'
  },
  maxFiles: 10,
};

const coreFields = {
  id,
  gid,
  active,
  created_on,
  created_by,
  modified_on,
  modified_by,
  geom,
  docs,
};

export default coreFields;
