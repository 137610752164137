import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Custom components
import TenantContext from '@/context/TenantContext/TenantContext';

// Types
import { ITenantState, ITenantStatePreParsed } from '@/@types/common';

type TenantProviderProps = {
  children: JSX.Element | JSX.Element;
};

const TenantProvider: FunctionComponent<TenantProviderProps> = (
  props: React.PropsWithChildren<TenantProviderProps>
) => {
  const { t } = useTranslation();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [tenant, setTenant] = useState<ITenantState>({
    tenantId: 0,
    tenantName: '',
    publicToken: '',
    mapSettings:{
      default_extent: [],
      max_extent: [],
      initial_view_center: [0,0],
      initial_view_zoom: 0,
      map_min_view_zoom: 0,
      map_max_view_zoom: 0,
    },
    tenantTheme: {
      primary_main: '',
      primary_dark: '',
      primary_light: '',
      secondary_main: '',
      secondary_dark: '',
      secondary_light: '',
      crest: undefined,
      background_image: undefined,
      background_image_type: undefined,
      crest_type: undefined
    }
  });

  const setTenantData = (data: ITenantStatePreParsed): void => {
    const { tenant_id, name_ttoken, public_token, theme, map_settings } = data;

    setLoaded(true);
    setTenant({
      tenantId: tenant_id,
      tenantName: t(name_ttoken),
      publicToken: public_token,
      tenantTheme: theme,
      mapSettings: {
        max_extent: map_settings.map_max_extent,
        default_extent: map_settings.map_default_extent,
        initial_view_center: map_settings.map_initial_view_center,
        initial_view_zoom: map_settings.map_initial_view_zoom,
        map_min_view_zoom: map_settings.map_min_view_zoom,
        map_max_view_zoom: map_settings.map_max_view_zoom
      }
    });
  };

  // TODO eslint suggests wraping this in useMemo
  const providerValue = {
    loaded,
    id: tenant.tenantId,
    name: tenant.tenantName,
    publicToken: tenant.publicToken,
    tenantTheme: tenant.tenantTheme,
    mapSettings: tenant.mapSettings,
    setTenantData,
  };

  return (
    <TenantContext.Provider value={providerValue}>
      {props.children}
    </TenantContext.Provider>
  );
};

export default TenantProvider;
