import * as React from 'react';
import { FunctionComponent, useContext, useEffect, useState, Dispatch } from 'react';
import { useTranslation } from 'react-i18next';

//MUI
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";

//MUI Icons
import LanguageIcon from '@mui/icons-material/Language';
import SearchIcon from "@mui/icons-material/Search";
import ExploreIcon from "@mui/icons-material/Explore";
import SettingsIcon from '@mui/icons-material/Settings';
import HomeIcon from '@mui/icons-material/Home';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';

// Custom Components
import MapContext from "@/context/MapContext/MapContext";
import DataController from '@/lib/DataController';
import modelToponimi from "@/models/toponimi";
import modelToponimTipovi from "@/models/toponim_tipovi";

// Types
import { DCRecord } from '@/@types/lib/dataController';

// OpenLayers
import OlFormatWKT from "ol/format/WKT";
import { Menu, MenuItem, Switch, Typography, useTheme } from '@mui/material';
import { IMenuState } from '@/@types/ui/Table';
import OlSourceVector from 'ol/source/Vector';
import Geometry from 'ol/geom/Geometry';
import OlFeature from 'ol/Feature';

export interface SearchPaneProps {
  setSelectedSource: Dispatch<React.SetStateAction<OlSourceVector<Geometry>>>;
  searchUsingMW: boolean;
}

const SearchPane: FunctionComponent<any> = (props: SearchPaneProps) => {
  const { setSelectedSource, searchUsingMW } = props;

  const { t } = useTranslation();
  const mapContext = useContext(MapContext);
  const theme = useTheme();
  const dc = new DataController(modelToponimi);

  const inputRef = React.useRef<HTMLInputElement>(null);

  const [value, setValue] = useState<string>("");
  const [records, setRecords] = useState<DCRecord[]>([]);
  const [results, setResults] = useState<DCRecord[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [toponimTipovi, setToponimTipovi] = useState<Array<DCRecord>>([]);
  const [menuState, setMenuState] = useState<IMenuState>({ open: false, anchorEl: null });
  const [hiddenTypes, setHiddenTypes] = useState<Array<Number> | null>(null);

  useEffect(() => {
    loadToponimTipovi();
  }, []);

  useEffect(() => {
    if (toponimTipovi.length) {
      loadHiddenTypesFromStorage();
    }
  },[toponimTipovi])

  useEffect(() => {
    if (Array.isArray(hiddenTypes)) {
      saveHiddenTypesToStorage();
    }
  }, [hiddenTypes]);

  const loadHiddenTypesFromStorage = () => {
    try {
      const hiddenTypesStringified = localStorage.getItem("hidden_search_toponyms");
      if (hiddenTypesStringified) {
        const parsedHiddenTypes = JSON.parse(hiddenTypesStringified);
        // Validate that parsedHiddenTypes is an array of numbers
        if (Array.isArray(parsedHiddenTypes) && parsedHiddenTypes.every((x) => typeof x === "number")) {
          setHiddenTypes(parsedHiddenTypes);
        } else {
          console.warn("Invalid hiddenTypes data in localStorage, resetting to default.");
          setHiddenTypes([]);
        }
      }
    } catch (error) {
      console.error("Error parsing hiddenTypes from localStorage", error);
      setHiddenTypes([]); // Fallback to empty array in case of error
    }
  };

  const saveHiddenTypesToStorage = () => {
    try {
      localStorage.setItem("hidden_search_toponyms", JSON.stringify(hiddenTypes));
    } catch (error) {
      console.error("Error saving hiddenTypes to localStorage", error);
    }
  };

  useEffect(() => {
    if(toponimTipovi && toponimTipovi !== null && toponimTipovi.length > 0 && searchUsingMW === false) {
      reloadToponimi();
    }
  }, [toponimTipovi]);

  useEffect(() => {
    if (!((records.length === 0 && searchUsingMW === false) || (toponimTipovi.length === 0)) && inputRef.current) {
      inputRef.current.focus()
    }
  }, [records, searchUsingMW, toponimTipovi]);

  useEffect(() => {
    if (value.length >= 3) {
      if (searchUsingMW === true) {
        const timer = setTimeout(() => {
          reloadToponimi(value);
        }, 500);
  
        return () => {
          clearTimeout(timer);
        };
      } else {
        const filtered = filterResults(value, records);
        setResults(filtered);
      }
    } else {
      setResults([]);
    }
    return;
  }, [value, searchUsingMW]);

  const loadToponimTipovi = () => {
    const dcTipovi = new DataController(modelToponimTipovi);

    dcTipovi.GetData().then((resp) => {
      if (resp.success) {
        if (Array.isArray(resp.data)) {
          setToponimTipovi(resp.data || []);
        }
        else {
          setToponimTipovi([]);
        }
      };
    });
  }

  const filterResults = (value: string, records: DCRecord[]) => {
    const value_deacc = deaccent(value as string);
    const res = records.filter(x => {
      const naziv = x["naziv"] as string;
      const naziv_alt = x["naziv_alt"];
      const found = false;
      if (naziv) {
        const found = deaccent(naziv).indexOf(value_deacc) >= 0;
        if (found) {
          return true;
        } else if (naziv_alt) {
          return deaccent(naziv_alt as string).indexOf(value_deacc) >= 0;
        } else {
          return false;
        }
      } else {
        return false;
      }
    });
    return res;
  }

  const reloadToponimi = (searchString?: string) => {
    // Load only searched
    if(searchString) {
      setLoading(true);
      dc.GetData('core/toponimi-search/' + value).then((resp) => {
        if (resp.success) {
          if (Array.isArray(resp.data)) {
            setRecords(resp.data || []);
            setResults(resp.data);
          }
          else {
            setRecords([]);
            setResults([]);
          }
        };
      }).finally(() => {
        setLoading(false);
      });
    // Load all
    } else {
      setLoading(true);
      dc.GetData().then((resp) => {
        if (resp.success) {
          if (Array.isArray(resp.data)) {
            setRecords(resp.data || []);
          }
          else {
            setRecords([]);
          }
        };
      })
      .finally(() => {
        setLoading(false);
      })
    }
  }

  const deaccent = (value: string) => {
    let r = value.toLowerCase();
    r = r.replace(new RegExp(/[čć]/g),"c");
    r = r.replace(new RegExp(/[ž]/g),"z");
    r = r.replace(new RegExp(/[š]/g),"s");
    r = r.replace(new RegExp(/[đ]/g),"d");
    
    return r;
  }

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const val = evt.target.value;
    setValue(val);
  }

  const handleExplore = (evt: React.MouseEvent<HTMLButtonElement>, item: DCRecord) => {
    const wkt = item["wkt"];
    if (wkt) {
      const wktFormatter = new OlFormatWKT();
      const feat = wktFormatter.readFeature(wkt, {
        dataProjection: "EPSG:3765",
        featureProjection: "EPSG:3857"
      })

      // @ts-ignore
      const searchSource = new OlSourceVector({features: [feat] as OlFeature<Geometry>});
      setSelectedSource(searchSource);

      setTimeout(() => {
        const extent = feat.getGeometry()?.getExtent();
        if (mapContext && mapContext.map) {
          const view = mapContext.map.getView();
          if (extent)
            view.fit(extent, { padding: [20, 20, 20, 220], duration: 500, maxZoom: 19 });
        }
      }, 10)
    }
  }

  const handleOpenSearchSettings = (evt: React.MouseEvent<HTMLButtonElement>) => {
    setMenuState({ open: true, anchorEl: evt.currentTarget });
  }

  const handleCloseSearchSettings = (evt: React.MouseEvent<HTMLButtonElement>) => {
    setMenuState({ open: false, anchorEl: null });
  }

  const handleToggleToponimTip = (evt: any, tip_id: number) => {
    if (hiddenTypes) {
      if(hiddenTypes.includes(tip_id)) {
        setHiddenTypes(hiddenTypes.filter((x) => x !== tip_id));
      } else {
        const newHiddenTypes = hiddenTypes.concat([tip_id]);
        setHiddenTypes(newHiddenTypes);
      }
    }
  }

  const icons: {[key:string]: React.ReactNode} = {
    '1': <HomeIcon color="primary"/>,
    '2': <AccountBoxIcon color="primary"/>,
    '3': <LanguageIcon color="primary"/>,
    '4': <RequestPageIcon color="primary"/>,
    'default' : <TravelExploreIcon color="primary"/>
  }

  return (
    <>
      <TextField
        inputRef={inputRef}
        sx={{padding: "10px 20px"}}
        value={value}
        onChange={handleChange}
        disabled={(records.length === 0 && searchUsingMW === false) || (toponimTipovi.length === 0)}
        fullWidth
        placeholder={t('map:sidebar.search_placeholder') as string}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {loading ? <CircularProgress size={20} /> : <SearchIcon />}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {loading || toponimTipovi.length === 0 ? null : <>
                <IconButton onClick={handleOpenSearchSettings} >
                  <SettingsIcon />
                </IconButton>
                <Menu
                  open={menuState.open}
                  onClose={handleCloseSearchSettings}
                  anchorEl={menuState.anchorEl}
                  transformOrigin={{
                    vertical: "center",
                    horizontal: "right"
                  }}
                >
                  {toponimTipovi.map((tip, i) => {
                    return (
                      <MenuItem value={i} onClick={(evt) => handleToggleToponimTip(evt, tip.id as number)} key={i}>
                        <Switch
                          color="primary"
                          checked={hiddenTypes ? !hiddenTypes.includes(tip.id as number) : true}
                          value={i}
                        />
                        <Typography variant="caption" style={{ textTransform: "uppercase", fontWeight: 600 }}>
                          {t('map:toponimi.' + tip.toponim_tip as string)}
                        </Typography>
                      </MenuItem>
                    ) 
                  })}
                </Menu>
              </>}
            </InputAdornment>
          ),
        }}
      />
      {results ?
      <List sx={{
        width: '100%',
        height: '100%',
        bgcolor: 'background.paper',
        position: 'relative',
        overflow: 'auto',
        '& ul': { padding: 0 }
      }}
      subheader={<li />}>
        {toponimTipovi.filter((toponimTip) => !hiddenTypes?.includes(toponimTip.id as number)).map((toponimTip, index) => (
          <li key={`section-${toponimTip.id}`}>
            <ul>
              {results.filter(x => x["toponim_tip_id"] === toponimTip.id).length > 0 ? 
                <ListSubheader>
                  {t('map:toponimi.' + toponimTip.toponim_tip as string) + ' (' + results.filter(x => x["toponim_tip_id"] === toponimTip.id).length + ')'}
                </ListSubheader> 
              : null}
              {results.filter(x => x["toponim_tip_id"] === toponimTip.id).map((item, index) => {
                return (
                  <ListItem
                    key={`item-${toponimTip.id}-${item["id"]}-${index}`}
                    secondaryAction={
                      <IconButton edge="end" aria-label="explore" onClick={(evt) => handleExplore(evt, item)}>
                        <ExploreIcon color="primary" />
                      </IconButton>
                    }>
                    <ListItemAvatar>
                      <Avatar sx={{backgroundColor: theme.palette.background.default}}>
                        {icons[toponimTip.id as string] !== undefined && icons[toponimTip.id as string] !== null ? icons[toponimTip.id as string] : icons['default']}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={`${item["naziv"]}`} secondary={`${item["opis"]}`} />
                  </ListItem>
                );
              })}
            </ul>
          </li>
        ))}
      </List>
      : null}
    </>
  );
};

export default SearchPane;
