import React, { useEffect, useContext, useRef, useState, useCallback, useLayoutEffect } from 'react';

// MUI
import { Box } from '@mui/material';

// Misc
import Draggable from 'react-draggable';

// Custom
import MapContext, { MapContextType } from '@/context/MapContext/MapContext';
import { findControl, getDefinedOptions, getEvents } from '@/lib/olHelpers';

import { DraggableRootProps } from '@/@types/components/Map/Controls/DraggableCard';
import DraggableControl from './draggablecontrol';

const DraggableRoot = (props: DraggableRootProps) => {
  const mapContext = useContext(MapContext) as MapContextType;
  const draggableCardRef = useRef<HTMLDivElement>(null);

  const { id, handleClassname, position, isVisible, defaultClassName } = props;

  const xOffset = 48; // Due to open layers popup pointy arrow to click location

  const [currentPosition, setCurrentPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });

  useLayoutEffect(() => {
    if (isVisible && position.length > 0 && mapContext.map && draggableCardRef.current) {
      const pos = mapContext.map.getPixelFromCoordinate(position);
      const yOffset = draggableCardRef.current.clientHeight + 13; // 11 is due to open layers popup pointy arrow height

      setCurrentPosition({ x: pos[0] - xOffset, y: pos[1] - yOffset });
    }
  }, [position, mapContext.map, isVisible]);

  const handleDrag = useCallback((e: any, data: any) => {
    setCurrentPosition({ x: data.x, y: data.y });
  }, []);

  useEffect(() => {
    if (draggableCardRef.current) {
      const options = {
        element: draggableCardRef.current,
      };

      let allOptions = Object.assign(options, props);
      let definedOptions = getDefinedOptions(allOptions);

      const events = {};

      let control = new DraggableControl(definedOptions);

      if (id) {
        control.set("id", id);
      }

      if (mapContext.map) {
        const mapControl = findControl(mapContext.map, id, DraggableControl);
        if (mapControl) {
          mapContext.map.removeControl(mapControl);
        }
        mapContext.map.addControl(control);
      } else {
        mapContext.initOptions.controls.push(control);
      }

      let olEvents = getEvents(events, props);
      for (let eventName in olEvents) {
        // @ts-ignore
        control.on(eventName, olEvents[eventName]);
      }
    }

    return () => {
      if (mapContext.map) {
        const mapControl = findControl(mapContext.map, id, DraggableControl);
        if (mapControl) {
          mapContext.map.removeControl(mapControl);
        }
      }
    };
  }, [id]);

  return (
    <Draggable
      defaultClassName={defaultClassName}
      handle={handleClassname}
      position={currentPosition}
      onDrag={handleDrag}
      bounds='parent'
    >
      <Box ref={draggableCardRef} width={"fit-content"}>
        {props.children}
      </Box>
    </Draggable> 
  );
};

export default DraggableRoot;
