import React, { useContext, useState, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// MUI Components
import Toolbar from '@mui/material/Toolbar';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Card from '@mui/material/Card';

// MUI Icons
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import MapIcon from '@mui/icons-material/Map';
import PublicIcon from '@mui/icons-material/Public';
import { mapLayerService } from '@/services/mapLayerService';

// Custom Icons
import MapLayerIcon from '@/icons/MapLayerIcon';

// Custom Components
import FormContent, { FormContentMode } from '@/components/FormContent';
import useFormController from '@/components/useFormController';
import { GridContainer, GridItem } from '@/ui/Grid';
import {
  CardActionButtonSave,
  CardHeading,
  CardToolbarButtonBack,
  CardToolbarMenuButton,
  CardToolbarMenuItemActivate,
  CardToolbarMenuItemDeactivate,
  CardToolbarMenuItemDelete,
} from '@/ui/Card';
import {
  FormAvatar,
  FormHeading,
  FormHeadingID,
  FormFillContent,
  FormTab,
  FormTabPanel,
  FormTabs,
} from '@/ui/Form';

// Contexts
import SnackbarContext, { SnackbarContextType } from '@/context/SnackbarContext/SnackbarContext';

// Lib
import dataController from '@/lib/DataController';

interface IMapLayerFormProps {
  dc: dataController;
  recordId: number;
  mode: FormContentMode;
  form: string;
}

const MapLayerForm = (props: IMapLayerFormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const snackbarContext = useContext(SnackbarContext) as SnackbarContextType;

  const [currentTab, setCurrentTab] = useState(0);

  const { dc, recordId, mode, form } = props;
  const {
    record,
    validation,
    fields,
    onFieldChange,
    doUpdate,
    doDelete,
    doRefresh,
    doClose,
    pickerDataControllers,
    dataChanged,
  } = useFormController({
    mode,
    recordId,
    form,
    dc,
    onClose: close,
  });

  const handleClickBack = () => {
    if (doClose) {
      doClose()
        .then((resp) => {
          if (resp.success) {
            navigate('/admin/map_layers');
          } else if (resp.shouldSave) {
            if (doUpdate) {
              doUpdate()
                .then((result) => {
                  if (result.success) {
                    const msg = 'messages.update_map_layer_success';
                    snackbarContext.showNotification(msg, 'success');
                    navigate('/admin/map_layers');
                  }
                })
                .catch((result) => {
                  if (
                    result.success === false &&
                    result.validationPass === false
                  ) {
                    // do nothing
                  } else {
                    const msg = 'error.update_map_layer';
                    snackbarContext.showNotification(msg, 'error');
                  }
                });
            }
          }
        })
        .catch((result) => {
          snackbarContext.showNotification(result.error, 'error');
        });
    }
  };

  const handleTabChange = (value: number) => {
    setCurrentTab(value);
  };

  const handleUpdate = (evt: any) => {
    if (doUpdate) {
      doUpdate()
        .then((result) => {
          if (result.success) {
            const msg = 'messages.update_map_layer_success';
            snackbarContext.showNotification(msg, 'success');
          }
        })
        .catch((result) => {
          if (result.success === false && result.validationPass === false) {
            // do nothing
          } else {
            const msg = 'error.update_map_layer';
            snackbarContext.showNotification(msg, 'error');
          }
        })
        .finally(() => {
          doRefresh();
        });
    }
  };

  const handleDelete = (evt: any) => {
    if (doDelete) {
      doDelete()
        .then((result) => {
          if (result.success) {
            const msg = 'messages.delete_map_layer_success';
            snackbarContext.showNotification(msg, 'success');
            navigate('/admin/map_layers');
          }
        })
        .catch((result) => {
          const msg = 'error.delete_map_layer';
          snackbarContext.showNotification(msg, 'error');
        });
    }
  };

  const handleActivate = (evt: SyntheticEvent) => {
    if (record.id !== null) {
      try {
        mapLayerService.activate(Number(record.id)).then((result) => {
          if (result) {
            const msg = 'messages.activate_map_layer_success';
            snackbarContext.showNotification(msg, 'success');
            doRefresh();
          } else {
            const msg = 'error.activate_map_layer';
            snackbarContext.showNotification(msg, 'error');
          }
        });
      } catch (error) {
        const msg = 'error.activate_map_layer';
        snackbarContext.showNotification(msg, 'error');
      }
    }
  };

  const handleDeactivate = (evt: SyntheticEvent) => {
    if (record.id !== null) {
      try {
        mapLayerService.deactivate(Number(record.id)).then((result) => {
          if (result) {
            const msg = 'messages.deactivate_map_layer_success';
            snackbarContext.showNotification(msg, 'success');
            // close({ dataChanged: true, action: 'update' });
            navigate(-1);
          } else {
            const msg = 'error.deactivate_map_layer';
            snackbarContext.showNotification(msg, 'error');
          }
        });
      } catch (error) {
        const msg = 'error.deactivate_map_layer';
        snackbarContext.showNotification(msg, 'error');
      }
    }
  };

  const commonProps = {
    record,
    validation,
    onFieldChange,
    fields,
    mode,
    pickerDataControllers,
  };

  const title = record.code ? (record.code as string) : undefined;

  const showSaveButton = currentTab === 0 || currentTab === 1 || currentTab === 2;
  const disableSaveButton = !dataChanged;

  return (
    <Card>
      <CardHeading>
        <Toolbar variant="dense" disableGutters>
          <FormAvatar ariaLabel="map_layer" icon={<MapLayerIcon />} />
          <FormHeading
            header={title}
            subheaderContent={<FormHeadingID id={recordId} />}
            dataChanged={dataChanged}
          />
          <FormFillContent />
          <CardToolbarButtonBack onClick={handleClickBack} />
          <CardToolbarMenuButton>
            {record.active ? (
              <CardToolbarMenuItemDeactivate onClick={handleDeactivate} />
            ) : (
              <CardToolbarMenuItemActivate onClick={handleActivate} />
            )}
            <CardToolbarMenuItemDelete onClick={handleDelete} />
          </CardToolbarMenuButton>
        </Toolbar>
      </CardHeading>
      <CardContent style={{ paddingTop: 0 }}>
        <FormTabs
          value={currentTab}
          onChange={handleTabChange}
          identifier={dc.getSource()}
        >
          <FormTab
            id="basic"
            label={t('admin:common.tabs.basic')}
            icon={<BusinessCenterIcon />}
            aria-label="basic"
            value={0}
            validation={validation}
            fields={[
              'ui_code',
              'ui_title',
              'ui_ttoken',
              'map_id',
              'tenant_id',
              'gfi_model_id',
              'ui_legend',
              'legend_conf_id',
              'layer_functionality_id'
            ]}
          />
          <FormTab
            id="ui-config"
            label={t('admin:common.tabs.config-ui')}
            icon={<MapIcon />}
            aria-label="ui-config"
            value={1}
            fields={[
              'ui_sortorder',
              'ui_z_index',
              'ui_has_opacity_slider',
              'ui_extent',
              'ui_visible',
              'ui_zoomable',
              'use_cache',
              'ui_parent_layer_code',
            ]}
          />
          <FormTab
            id="gs-config"
            label={t('admin:common.tabs.config-gs')}
            icon={<PublicIcon />}
            aria-label="gs-config"
            value={2}
            fields={[
              'gs_layer',
              'gs_layer_source',
              'gs_layer_type',
              'gs_format',
              'gs_url',
              'gs_projection',
              'gs_styles',
              'gs_tiled',
              'gs_can_query',
              'gs_call_group',
              'gs_timeseries',
              'gs_visible_min_zoom',
              'gs_visible_max_zoom',
            ]}
          />
        </FormTabs>
        <FormTabPanel value={currentTab} index={0}>
          <GridContainer direction="row" spacing={3}>
            <GridItem>
              <GridContainer direction="row">
                <FormContent
                  title={t('admin:common.sections.basic') as string}
                  fieldNames={[
                    'ui_code',
                    'ui_title',
                    'ui_ttoken',
                    'map_id',
                    'tenant_id',
                    'gfi_model_id',
                    'ui_legend',
                    'legend_conf_id',
                    'layer_functionality_id'
                  ]}
                  {...commonProps}
                  columns={3}
                />
              </GridContainer>
            </GridItem>
          </GridContainer>
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={1}>
          <GridContainer direction="row" spacing={3}>
            <GridItem>
              <GridContainer direction="row">
                <FormContent
                  title={t('admin:common.sections.ui_config') as string}
                  fieldNames={[
                    'ui_sortorder',
                    'ui_z_index',
                    'ui_has_opacity_slider',
                    'ui_extent',
                    'ui_visible',
                    'ui_zoomable',
                    'use_cache',
                    'ui_parent_layer_code',
                  ]}
                  {...commonProps}
                  columns={3}
                />
              </GridContainer>
            </GridItem>
          </GridContainer>
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={2}>
          <GridContainer direction="row" spacing={3}>
            <GridItem>
              <GridContainer direction="row">
                <FormContent
                  title={t('admin:common.sections.gs_info') as string}
                  fieldNames={[
                    'gs_layer',
                    'gs_layer_source',
                    'gs_layer_type',
                    'gs_format',
                    'gs_url',
                    'gs_projection',
                  ]}
                  {...commonProps}
                  columns={3}
                />
                <FormContent
                  title={t('admin:common.sections.gs_config') as string}
                  fieldNames={[
                    'gs_styles',
                    'gs_tiled',
                    'gs_can_query',
                    'gs_call_group',
                    'gs_timeseries',
                    'gs_visible_min_zoom',
                    'gs_visible_max_zoom',
                  ]}
                  {...commonProps}
                  columns={3}
                />
              </GridContainer>
            </GridItem>
          </GridContainer>
        </FormTabPanel>
      </CardContent>
      <CardActions>
        <FormFillContent />
        {showSaveButton ? (
          <CardActionButtonSave
            onClick={handleUpdate}
            variant="contained"
            disabled={disableSaveButton}
          />
        ) : null}
      </CardActions>
    </Card>
  );
}

export default MapLayerForm;
