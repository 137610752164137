// Custom components
import standardHeadersWithAuth from './standardHeadersWithAuth';
import downloadHeadersWithAuth from './downloadHeadersWithAuth';
import handleResponse from './handleResponse';

// Types
import {
  IApiResponse,
  IApiResponseFailure,
  IDocumentResponse,
  IFileResponse,
  MethodVerbs,
} from '../../@types/lib/api';

export interface IApi {
  get: (path: string) => Promise<IApiResponse>;
  post: (path: string, data?: object | null) => Promise<IApiResponse>;
  put: (path: string, data: object) => Promise<IApiResponse>;
  patch: (path: string, data?: object) => Promise<IApiResponse>;
  remove: (path: string, data: object) => Promise<IApiResponse>;
  download: (type: string, key?: string) => Promise<any | IApiResponseFailure>;
  downloadWithMetadata: (typeOrUrl: string, key?: string) => Promise<IDocumentResponse>;
}

const apiUrl = process.env.REACT_APP_APIPATH;

const useApi = (): IApi => {
  const makeRequest = async (
    url: string,
    method: MethodVerbs,
    body: object | null = null
  ): Promise<IApiResponse> => {
    const options: RequestInit = {
      method,
      mode: 'cors',
      headers: standardHeadersWithAuth(),
      body: body ? JSON.stringify(body) : null,
    };

    try {
      const response = await fetch(url, options);
      const data = await handleResponse(response);
      return { success: true, data };
    } catch (error: any) {
      return { success: false, error };
    }
  };

  const get = (path: string): Promise<IApiResponse> => {
    const url = `${apiUrl}${path}`;
    return makeRequest(url, 'GET');
  };

  const post = (
    path: string,
    data: object | null = null
  ): Promise<IApiResponse> => {
    const url = `${apiUrl}${path}`;
    return makeRequest(url, 'POST', data);
  };

  const put = (path: string, data: object): Promise<IApiResponse> => {
    const url = `${apiUrl}${path}`;
    return makeRequest(url, 'PUT', data);
  };

  const patch = (
    path: string,
    data: object | null = null
  ): Promise<IApiResponse> => {
    const url = `${apiUrl}${path}`;
    return makeRequest(url, 'PATCH', data);
  };

  const remove = (path: string, data: object): Promise<IApiResponse> => {
    const url = `${apiUrl}${path}`;
    return makeRequest(url, 'DELETE', data);
  };

  const download = (
    typeOrUrl: string,
    key?: string
  ): Promise<IFileResponse> => {
    const url = key ? `${typeOrUrl}/${key}` : `${typeOrUrl}`;
    return fetch(`${apiUrl}${url}`, {
      method: 'get',
      mode: 'cors',
      headers: standardHeadersWithAuth(),
    })
      .then((data) => {
        const tmp = data.blob();
        return Promise.resolve({ success: true, data: tmp });
      })
      .catch((error) => Promise.reject({ success: false, error }));
  };

  const downloadWithMetadata = (
    typeOrUrl: string,
    key?: string
  ): Promise<IDocumentResponse> => {
    const url = key ? `${typeOrUrl}/${key}` : `${typeOrUrl}`;
    return fetch(`${apiUrl}${url}`, {
      method: 'get',
      mode: 'cors',
      headers: standardHeadersWithAuth(),
    })
      .then((res) => {
        return res.json();
      }).then (data => {
        return Promise.resolve({ success: true, data: data });
      })
      .catch((error) => Promise.reject({ success: false, error }));
  };

  return {
    get,
    post,
    put,
    patch,
    remove,
    download,
    downloadWithMetadata,
  };
};

export default useApi;
