import React from "react"
import { useTranslation } from "react-i18next";

//MUI
import {IconButton, Tooltip} from "@mui/material"

//MUI Icons
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';

//CUSTOM
import MapCard from "@/ui/MapCard/MapCard";
import MapCardHeader from "@/ui/MapCard/MapCardHeader";
import MapCardAvatar from "@/ui/MapCard/MapCardAvatar";
import MapCardContent from "@/ui/MapCard/MapCardContent";
import MapCardActions from "@/ui/MapCard/MapCardActions";
import DataController from "@/lib/DataController";
import MapCardHeaderButtonClose from "@/ui/MapCard/MapCardHeaderButtonClose";
import { GridContainer, GridItem } from "@/ui/Grid";
import FormContent from "@/components/FormContent";
import { DCRecord } from "@/@types/lib/dataController";
import modelLokacije from "@/models/lokacije";

type EntityInfoPaneProps = 
{
    record: DCRecord | undefined;
    onClose: () => void;
    focusOnObject: (id: number) => void;
    entityId: number;
    trackedEntityId: number;
}

const EntityInfoPane = (props: EntityInfoPaneProps) => {

  const { t } = useTranslation();

  const { record, entityId, trackedEntityId, onClose, focusOnObject } = props

  const dc = new DataController(modelLokacije);
  const form = "default";
  const faIconClass = "fas fa-info ";

  const title = t("ena:komunalni_obveznici.lok")

  //console.log(record)

  return ( 
    record ?
      <MapCard sx={{width: "min-content"}}>  
        <MapCardHeader
          title={title}
          subheader={t("common.id") + ": "+ record.id}
          subheaderTypographyProps={{ color: "initial" }}
          avatar={
            <MapCardAvatar
              ariaLabel="info"
              icon={<i className={faIconClass}></i>}
            />
          }
          action={<MapCardHeaderButtonClose onClick={()=>{onClose()}} />}
        />
        <MapCardContent>
          <GridContainer spacing={0}>
            <GridItem xs={12} container>
              <GridContainer spacing={0}>
                { record &&
                  <FormContent
                    fields={dc.getFormFields(form)}
                    fieldNames={dc.getFormFieldsNames(form)}
                    columns={1}
                    mode="simpleView"
                    record={record}
                  />
                }
              </GridContainer>
            </GridItem>
          </GridContainer>
        </MapCardContent>
          <MapCardActions style={{ justifyContent: "flex-end", paddingTop: 0}} sx={{display: "flex",flexDirection: "row", width: "100%"}}>
            <IconButton onClick={()=>{
              focusOnObject(entityId as number);
            }}
            color={trackedEntityId===entityId?"primary":"default"}
            >
            <Tooltip sx={{zIndex: 1201
            }} placement="left" title={""+t("buttons.focus_object")}>
              <VideoCameraBackIcon/>
            </Tooltip>
          </IconButton>
        </MapCardActions>
      </MapCard>
    :  <></>
  )
}

export default EntityInfoPane;
