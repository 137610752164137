import * as React from 'react';

import AppPage from '@/ui/AppPage/AppPage';
import Box from '@mui/material/Box';
import Permissions from '@/lib/permissions';
import UserTenantsTable from './UserTenantsTable';

function UserTenantsPage() {
  return (
    <AppPage titlett="admin:page.users" needPermission={[Permissions.TenantUserAdministration]}>
      <Box m={0} style={{ height: '100%' }}>
        <UserTenantsTable />
      </Box>
    </AppPage>
  );
}

export default UserTenantsPage;
