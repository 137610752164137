import React, {useCallback, useContext, useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next';

// File dropzone
import { useDropzone } from 'react-dropzone';
import '@'


// OL
import GeoJSON from 'ol/format/GeoJSON';
import WKT from 'ol/format/WKT';
import { Feature } from 'ol';
import { Geometry } from 'ol/geom';
import Point from 'ol/geom/Point';
import Polygon from 'ol/geom/Polygon';

// Types
import { DCFieldValue, DCFile, DCRecord } from '@/@types/lib/dataController';
import { IFieldFile, IFieldsCollection } from '@/@types/models/model';
import { PickerDataControllers } from '@/@types/components/authFormController';
import { RecordValidation } from '@/@types/lib/appValidator';
import { ITenantCommonProps } from '@/@types/views/Tenants/tenants';


// Custom components
import FormContent, { FormContentMode } from '@/components/FormContent'
import TenantConfigMap from '@/views/Tenants/TenantConfig/TenantConfigMap';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { FileControl } from '@/controls';
import { ChangeHandlerFunctionT } from '@/@types/lib/formGenerator';
import { GridContainer, GridItem } from '@/ui/Grid';
import SnackbarContext, { SnackbarContextType } from '@/context/SnackbarContext/SnackbarContext';
import { ViewOptionsType } from '@/@types/common';
import { features } from 'process';

const TenantFormConfigTab = (props:ITenantCommonProps) => {

    const { t } = useTranslation();

    const { record } = props;

    const snackbarContext = useContext(SnackbarContext) as SnackbarContextType;

    const [tenantGeom, setTenantGeom] = useState<Feature<Geometry> | undefined>(undefined);
    const [maxExtentGeom, setMaxExtentGeom] = useState<Feature<Polygon> | undefined>(undefined);
    const [centerGeom, setCenterGeom] = useState<Feature<Point> | undefined>(undefined);
    const [defaultExtentGeom, setDefaultExtentGeom] = useState<Feature<Polygon> | undefined>(undefined);
  
    useEffect(()=>{

        if (!record) return;

        if (record.geom && record.geom!=="") {
            setTenantGeom(WKTToGeom(props.record.geom as string))
        }

        if (record.map_initial_view_center && record.map_initial_view_center!==null) {
            if (typeof record.map_initial_view_center==='string') {
                setCenterGeom(new Feature(stringArrayToPoint(record.map_initial_view_center as string)))
            }  else {
                setCenterGeom(new Feature(arrayToPoint(record.map_initial_view_center as [number,number])))
            }
        }

        if (record.map_default_extent && record.map_default_extent!=="") {
            if (typeof record.map_default_extent==='string') {
                setDefaultExtentGeom(new Feature(stringArrayToPolygon(record.map_default_extent as string)))
            }  else {
                setDefaultExtentGeom(new Feature(arrayToPolygon(record.map_default_extent as number[])))
            }
           
        }

        if (record.map_max_extent && record.map_max_extent!=="") {
            if (typeof record.map_max_extent==='string') {
                setMaxExtentGeom(new Feature(stringArrayToPolygon(record.map_max_extent as string)))
            }  else {
                setMaxExtentGeom(new Feature(arrayToPolygon(record.map_max_extent as number[])))
            }
        }

    },[record])

    function onChangeHandler(value: DCFile[], source: string): void {

        const file = value[0];
        const geom = base64FileToFeature(file);

        setTenantGeom(geom);
        
        if (!geom) {
            snackbarContext.showNotification('upload_error', 'error');
            return;
        } 
        const wkt = geomToWkt(geom);

        if (wkt===undefined) {
            snackbarContext.showNotification('upload_error', 'error');
            return;
        } 

        props.onFieldChange(wkt,"geom")
        snackbarContext.showNotification('upload_success', 'success');
    }

    function base64FileToFeature(file: DCFile) {
        try {
            if (!file.content) return undefined;

            const geoJsonString = atob(file.content)

            const GeoJsonFormat = new GeoJSON();
    
            const feature = GeoJsonFormat.readFeature(geoJsonString);
    
            return feature;
        }
        catch (error) {
            console.log(error);
            snackbarContext.showNotification('upload_error', 'error');
            return undefined;
        }
    }

    function geomToWkt(geom: Feature<Geometry>) {
        const WKTFormat = new WKT();
        const geometry = geom.getGeometry()

        if (geometry===undefined) return;

        const wkt = WKTFormat.writeGeometry(geometry);
        return wkt;
    }

    function WKTToGeom(wkt: string): Feature<Geometry> | undefined {
        try {
            const WKTFormat = new WKT();
            const feature = WKTFormat.readFeature(wkt, {
                dataProjection: 'EPSG:3765', // we save geom as 3765, but other settings as 3857
                featureProjection: 'EPSG:3857', 
            });
            if (feature.getGeometry()?.getType()!=='MultiPolygon') return undefined;
            return feature;
        } catch (E) { // INVALID WKT FORMAT
            console.log(E); 
            return undefined;
        }
    }

    function arrayToPoint(array: number[]) { // watch out for errors
        try {

            if (array.length !== 2) {
                throw new Error('Invalid input: array should contain exactly 4 numbers');
            }

            return new Point([array[0],array[1]]);
        } catch (error) {
            console.error(error);
            return undefined;
        }
    }

    function stringArrayToPoint(arrayString: string) { // watch out for errors
        try {
            const array = arrayString.split(",");

            if (array.length !== 2) {
                throw new Error('Invalid input: array should contain exactly 4 numbers');
            }

            return new Point([parseInt(array[0]),parseInt(array[1])]);
        } catch (error) {
            console.error(error);
            return undefined;
        }
    }

    function arrayToPolygon(array: number[]) {  // watch out for errors
        try {
            
            if (array.length !== 4) {
                throw new Error('Invalid input: array should contain exactly 4 numbers');
            }

            const [x1, y1, x2, y2] = array;
            return new Polygon([[[x1, y1], [x2, y1], [x2, y2], [x1, y2], [x1, y1]]]);
        } catch (error) {
            console.error(error);
            return undefined;
        }
    }

    function stringArrayToPolygon(arrayString: string) {  // watch out for errors
        try {
            const array = arrayString.split(",");

            if (array.length !== 4) {
                throw new Error('Invalid input: array should contain exactly 4 numbers');
            }

            const [x1, y1, x2, y2] = [parseInt(array[0]),parseInt(array[1]),parseInt(array[2]),parseInt(array[3])];
            return new Polygon([[[x1, y1], [x2, y1], [x2, y2], [x1, y2], [x1, y1]]]);
        } catch (error) {
            console.error(error);
            return undefined;
        }
    }

    return (
        <GridContainer direction={"row"}>
           <GridItem xs={8}>
                <GridContainer direction={"row"}>
                    <GridItem xs={12} >
                        <Typography variant="h6" component="h2" paragraph>
                            {t('admin:tenant.sections.map') as string}
                        </Typography>
                    </GridItem>
                    <GridItem xs={4}>
                        <GridContainer direction="row" >
                            <FormContent
                                fieldNames={[
                                'map_default_extent',
                                'map_max_extent',
                                'map_initial_view_center',
                                'map_initial_view_zoom',
                                'map_min_view_zoom',
                                'map_max_view_zoom'
                                ]}
                                {...props}
                                columns={1}
                            />
                        </GridContainer>
                    </GridItem>
                    <GridItem xs={4}>
                        <GridContainer direction="row">
                            <FormContent
                                fieldNames={[
                                'geom',
                                ]}
                                {...props}
                                columns={1}
                            />
                            <Box mt={2} ml={2} width={"100%"}>
                                <FileControl
                                    title={t('admin:tenant.geojson_dropzone') as string}
                                    formMode='form'
                                    controlMode='edit'
                                    field={{type: "geojson"} as IFieldFile}
                                    multiple={false}
                                    value={null}
                                    onChange={onChangeHandler as ChangeHandlerFunctionT<DCFile | Array<DCFile> | null>}
                                    accept={"json"}
                                />
                            </Box>
                        </GridContainer>
                    </GridItem>
                    <GridItem xs={4}>
                        <TenantConfigMap
                            {...props}
                            tenantGeom={tenantGeom}
                            centerGeom={centerGeom}
                            maxExtentGeom={maxExtentGeom}
                            defaultExtentGeom={defaultExtentGeom}
                        />
                    </GridItem>
                    <GridItem xs={12} >
                        <Typography variant="h6" component="h2" paragraph>
                            {t('admin:tenant.SRID_warning') as string}
                        </Typography>
                    </GridItem>
                </GridContainer>
            </GridItem>
            <GridItem xs={4}>
                <GridContainer direction="row">
                    <FormContent
                        title={t('admin:tenant.sections.dem') as string}
                        fieldNames={[
                        'dem_path',
                        'dem_layer_names',
                        'gs_user',
                        ]}
                        {...props}
                        columns={1}
                    />
                </GridContainer>
           </GridItem>
      </GridContainer>
    )
}

export default TenantFormConfigTab;