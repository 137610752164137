import { IModel } from '../@types/models/model';
import commonFields from './helpers/commonFields';
import coreFields from './helpers/coreFields';

const Comments: IModel = {
  title: 'Comments',
  apiPath: 'comments',
  forms: {
    default: {
      fields: ['note', 'slike'],
    },
    insert: {
      fields: ['note', 'slike'],
    },
    update: {
      fields: ['note', 'slike'],
    },
  },
  listViews: {
    default: {
      fields: ['id', 'created_by', 'note', 'show_on_map'],
      hidden: ['id'],
    },
    admin: {
      fields: ['id', 'note', 'created_by', 'created_on', 'modified_by', 'modified_on'],
      hidden: ['modified_by', 'modified_on']
    },
    map: {
      fields: ['id', 'created_by', 'note'],
      hidden: ['id'],
    },
  },
  fields: [
    coreFields.id,
    {
      title: 'Objekt type',
      source: 'objekt_type',
      ttoken: 'comments.objekt_type',
      type: 'text',
    },
    {
      title: 'Objekt ID',
      source: 'objekt_id',
      ttoken: 'comments.objekt_id',
      type: 'numeric',
      filter: true,
      sort: true,
    },
    {
      title: 'Komentar',
      source: 'note',
      ttoken: 'comments.note',
      type: 'multiline',
      rows: 3,
      maxRows: 10,
      validation: {
        maxLength: 300,
        required: true,
      },
      filter: true,
    },
    {
      title: 'Created by',
      source: 'created_by',
      ttoken: 'comments.author',
      type: 'text',
      sort: true,
      filter: true,
    },
    {
      title: 'x',
      source: 'x',
      ttoken: 'data.x',
      type: 'numeric',
      readonly: true,
    },
    {
      title: 'y',
      source: 'y',
      ttoken: 'data.y',
      type: 'numeric',
      readonly: true,
    },
    {
      title: 'Datum i vrijeme kreacije',
      source: 'created_on',
      ttoken: 'common.created_on',
      type: 'datetime',
      filter: true,
      sort: true,
      readonly: true,
    },
    {
      title: 'Datum i vrijeme izmjene',
      source: 'modified_on',
      ttoken: 'common.modified_on',
      type: 'datetime',
      filter: true,
      sort: true,
      readonly: true,
    },
    {
      title: 'show_on_map',
      source: 'show_on_map',
      type: 'button',
      ttoken: 'buttons.show_on_map',
      icon: 'fmd_good',
      action: 'show_on_map',
      padding: 'none',
      sort: false,
    },
    {
      title: 'extent',
      source: 'extent',
      ttoken: 'comments.extent',
      type: 'array',
      readonly: true,
    },
    {
      title: 'geometry',
      source: 'geom',
      ttoken: 'comments.geometry',
      type: 'wkt',
      readonly: true,
    },
    {
      title: 'slike',
      source: 'slike',
      ttoken: 'comments.photos',
      type: 'images',
      maxFiles: 3,
    },
    {
      title: 'tenant_id',
      source: 'tenant_id',
      ttoken: 'comments.tenant_id',
      type: 'numeric',
      readonly: true,
    },
    coreFields.created_on,
    coreFields.modified_by,
    coreFields.modified_on
  ],
};

export default Comments;
