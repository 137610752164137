import { TFunction } from 'i18next';
import { nanoid } from 'nanoid'
import { Accept, FileRejection } from 'react-dropzone';
import { DCFile } from '@/@types/lib/dataController';
import { FileControlAcceptTypes } from '@/@types/controls/controls';

const verifyAndArrayFileValue = (value: DCFile | DCFile[] | null) => {
    if (!value || Array.isArray(value) && checkIfArrayWithEmptyObject(value)) return [];
    if (value && !Array.isArray(value)) return [ value ];
    return value;
}

function checkIfArrayWithEmptyObject(data: DCFile[]){
    try {
      if (!Array.isArray(data)) return false;
      return Object.keys(data[0]).length === 0 && data[0].constructor === Object;
    }
    catch(e) {
      return false;
    }
}

const generateRejectedFileValidation = (fileRejections: FileRejection[], t: TFunction) => {
  let rejectedFileNames =`${t("file.rejected")}: `
  for(const rejected of fileRejections){
    rejectedFileNames+=`${rejected.file.name  } (`;
    rejected.errors.forEach((error, i) =>{
      if (error.code)
      {
        rejectedFileNames += `${t(`file.${error.code}`)  }, `
      }
    });
    rejectedFileNames = rejectedFileNames.slice(0,-2)
    rejectedFileNames+="), ";
  }
  rejectedFileNames = rejectedFileNames.slice(0,-2 )
  if(fileRejections.length>0) 
  {
    return rejectedFileNames;
  }
  return 'null'

}

const rejectAcceptedFiles = <T extends File>(files:T[], t: TFunction) => {
  const tooManyFilesError = {
    code: 'too-many-files',
    message: 'Too many files'
  };

  const fileRejections = files.map(file => ({
    file,
    errors: [tooManyFilesError],
  }));

  return generateRejectedFileValidation(fileRejections, t);
};

const transformFilesToOurFormat = <T extends File>(files: T[]): Promise<any[]> => Promise.all(
      files.map((file) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            const newFileB64 = arrayBufferToBase64(reader.result as ArrayBuffer);
            const fileObj = {
              content: newFileB64,
              name: file.name,
              size: file.size,
              type: file.type,
              id: nanoid(),
            };
            resolve(fileObj);
          };
          reader.onerror = (e) => {
            console.error("File read error:", e);
            reject(e);
          };
          reader.readAsArrayBuffer(file);
        })
      )
    );
  
  function arrayBufferToBase64(buffer: ArrayBuffer | null): string {
    if (buffer === null) {
      throw new Error("Buffer is null");
    }
  
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
  
    return window.btoa(binary);
  }
  

function saveFile(file: DCFile) {
    if (!file.content) {
        console.error("File has no content")
    } else {
        const byteCharacters = atob(file.content);
        const byteNumbers = new Array(byteCharacters.length).fill(undefined).map((_, i) => byteCharacters.charCodeAt(i));
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: file.type });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = file.name;
        link.click();
    }
   
}

type IFileControlTypeMap = {
    [k in FileControlAcceptTypes]: Accept | {};
};

const FileControlTypeMap: IFileControlTypeMap = {
    images: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg', '.jpg'],
    },
    documents: {
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/vnd.oasis.opendocument.text': ['.odt'],
      // Add other document formats if needed
    },
    all: {},
    json: {
        'application/json': ['.json'],
      }
  };


export {
    verifyAndArrayFileValue,
    generateRejectedFileValidation,
    transformFilesToOurFormat,
    rejectAcceptedFiles,
    saveFile,
    FileControlTypeMap
};