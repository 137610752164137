import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

// Types
import { TCustomGFIDKPProps } from '@/@types/views/map';
import { DCRecord } from '@/@types/lib/dataController';

// Custom
import MapCardActionButton from '@/ui/MapCard/MapCardActionButton';
import DataController from '@/lib/DataController';
import modelPosjednici from '@/models/posjednici';
import ModelTable from '@/ui/Table/ModelTable';

// MUI
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { OpenInNew } from '@mui/icons-material';

const PosjedniciButton = (props: TCustomGFIDKPProps) => {
  const identifier = "posjednici";

  const [recordsPosjednici, setRecordsPosjednici] = useState<DCRecord[]>([]);

  const dcPosjednici = new DataController(modelPosjednici);

  const { t } = useTranslation();

  const { featureData, record, onToggle, addToCache, getFromCache } = props;

  const { layerData } = featureData;
  const { layer } = layerData;

  const recordId = useMemo(() => {
    return record ? (record.id ? record.id : record.fid ? record.fid : null) : null;
  }, [record]);

  const layerTag = useMemo(() => {
    const layerNameParts = layer.split("_", 2);
    return layerNameParts.length === 2 ? layerNameParts[1].toLowerCase() : layerNameParts[0].toLowerCase();
  }, [layer]);

  const loadPosjedniciDKP = useCallback(() => {
    if (record) {
      const cachedRecords = getFromCache(identifier, record.id as number)
      
      if (cachedRecords) {
        setRecordsPosjednici(cachedRecords);
      } else {
        dcPosjednici.GetData(`data/dkp/${record.id}/posjednici/`).then((resp) => {
          if (resp.success && Array.isArray(resp.data)) {
            setRecordsPosjednici(resp.data);
            addToCache(identifier, record.id as number, resp.data)
          }
        });
      }
    }
  }, [record]);

  const loadPosjedniciDKP2 = useCallback(() => {
    if (record) {
      const cachedRecords = getFromCache(identifier, record.id as number)
      if (cachedRecords) {
        setRecordsPosjednici(cachedRecords);
      } else {
        dcPosjednici.GetData(`data/dkp2/${record.id}/posjednici/`).then((resp) => {
          if (resp.success && Array.isArray(resp.data)) {
            setRecordsPosjednici(resp.data);
            addToCache(identifier, record.id as number, resp.data);
          }
        });
      }
    }
  }, [record]);

  useEffect(() => {
    if (recordId) {
      if (layerTag === 'dkp') {
        setRecordsPosjednici([]);
        loadPosjedniciDKP();
      } else if (layerTag === 'dkp2') {
        setRecordsPosjednici([]);
        loadPosjedniciDKP2();
      }
    }
  }, [recordId, layerTag, loadPosjedniciDKP, loadPosjedniciDKP2]);

  const memoizedModelTable = useMemo(() => (
    <ModelTable
      allowExport
      allowFilter
      title=""
      size='small'
      tableName="posjednici"
      viewName="default"
      records={recordsPosjednici || []}
      dc={dcPosjednici}
      allowSelection="none"
      allowAdd={false}
      allowRowAction={() => false}
      onRowAction={() => { }}
      disableControls={false}
      allowColumnPicker
    />
  ), [recordsPosjednici]);

  const handleTogglePosjednici = () => {
    onToggle(memoizedModelTable, identifier);
  };

  return (
    <MapCardActionButton
      startIcon={<AssignmentIndIcon />}
      onClick={handleTogglePosjednici}
      disabled={recordsPosjednici.length === 0}
      endIcon={<OpenInNew/>}
    >
      {t('dkp.posjednici')}
    </MapCardActionButton>
  );
};

export default PosjedniciButton;
